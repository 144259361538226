.notificationPage {
  display: flex;
  width: 100%;
  flex: 6;
  font-family: "Roboto", sans-serif;
}

.notificationPage-trainerRole {
  width: 100%;
  text-align: center;
  margin-top: 50px;
}

.notificationPage-mainContent {
  display: flex;
  flex-direction: row;
  background-color: var(--color-blue-50);
  height: calc(100vh - var(--heightTopBar));
  width: calc(100vw - var(--widthSideBarClosed));
}

.notificationPage-itemsContainer {
  width: 100%;
  overflow-y: scroll;
}

.notificationPage .notificationPage-notifications {
  margin: 10px 25px;
}

.notificationPage .notificationPage-loader {
  display: block;
  margin: 50px calc(50% - 50px);
}

@media only screen and (max-width: 600px) {
  .notification-mainContent {
    flex-direction: column;
    height: auto;
    width: 100vw;
  }
}
