.addButton {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 42px;
  height: 32px;
  border: none;
  background-color: var(--color-blue-700);
  cursor: pointer;
  padding: 0;
  margin: 0;
  border-radius: 4px;
}

.addButton span {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 600px) {
  .addButton {
    margin-left: 15px;
  }
}
