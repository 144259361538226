.appointmentType-popup .popup {
  top: calc(50% - 150px);
}

.appointmentType-popup .popup-overlay .popup-body {
  border-radius: 10px 10px 0 0;
  margin: 0;
}

.appointmentType-popup .popup .popup-footer {
  padding: 0 16px 10px 16px;
  border: none
}

.appointmentType-popup .popup-overlay .popup-overlay-mainContainer {
  padding: 30px 24px 0 24px;
}

.appointmentType-popup .popup-overlay .popup {
  top: 25%;
  left: 50%;
}

.appointmentType-popup .popup p {
  font-size: 15px;
  margin: 0 0 20px 0;
}
