.activitiesPage {
  display: flex;
  width: 100%;
  flex: 6;
  font-family: "Roboto", sans-serif;
}

.activitiesPage-container {
  flex: 5;
  display: flex;
  flex-direction: column;
}

.activitiesPage-mainContent {
  display: flex;
  height: calc(100vh - var(--heightTopBar));
  width: calc(100vw - var(--widthSideBarClosed));
  flex-direction: row;
}

.activitiesPage .activitiesPage-trainerRole {
  width: 100%;
  text-align: center;
  margin-top: 50px;
}

.activitiesPage-mainContent-tableWrapper {
  height: calc(100vh - var(--heightTopBar));
  width: calc(100vw - var(--widthSideBarClosed) - var(--widthFilterBar));
}

.activitiesPage .mfTable {
  grid-template-columns: repeat(7, auto) 70px;
}

.status {
  font-size: 14px;
  color: white;
  padding: 9px 15px;
  border-radius: 24px;
}

.statusScheduled {
  background-color: #007aff;
}

.statusCompleted {
  background-color: #af52de;
}

.statusInvoiced {
  background-color: #34c759;
}

.statusCancelled {
  background-color: #ff9500;
}

.statusCancelledFree {
  background-color: #c1c1c1;
}

.statusDeleted {
  background-color: #c73634;
}

.statusInvoicedCredit {
  background-color: #00918f;
}

.statusRescheduled {
  background-color: #718de9;
}

.statusRescheduledCancelled {
  background-color: #849b12;
}

.statusRescheduledCompleted {
  background-color: #df7cf8;
}

@media only screen and (max-width: 600px) {
  .activitiesPage-mainContent {
    flex-direction: column;
    height: auto;
    width: 100vw;
  }
}
