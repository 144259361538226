.authenticationPage {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: url('./login-background.jpg');
  background-size: cover;
  background-position: center;
}

.authenticationPage::before {
  position: absolute;
  content: '';
  display: block;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  filter: blur(1px) brightness(0.8);
  background-image: url('./login-background.jpg');
  background-size: cover;
  background-position: center;
}

.authenticationPage #MFLogo {
  position: absolute;
  top: calc(50% - 300px);
  left: calc(50% - 33px);
}

.authenticationPage .popup {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.authenticationPage .popup-titleContainer .popup-title {
  font-size: 24px;
  font-weight: 500;
}

.authenticationPage .popup-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0 auto 0 auto;
  background-color: #ffffff;
  border-radius: 10px;
  font-family: 'Roboto', sans-serif;
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.1);
  padding: 26px 30px;
}

.authenticationPage .popup-inputContainer {
  display: flex;
  flex-direction: column;
}

.authenticationPage .popup-popupButton {
  font-size: 15px;
  font-weight: 500;
  width: 100%;
}

.authenticationPage .popup-popupButton.disabled {
  background-color: #7db4e1;
  cursor: default;
}

.authenticationPage .popup-linkContainer {
  margin-top: 15px;
}
