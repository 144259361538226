.dashboardPage {
  display: flex;
  width: 100%;
  flex: 6;
  font-family: "Roboto", sans-serif;
}

.dashboardPage-container {
  flex: 5;
  display: flex;
  flex-direction: column;
}

.dashboardPage-mainContent {
  display: flex;
  flex-direction: row;
  background-color: var(--color-blue-50);
  height: calc(100vh - var(--heightTopBar));
}

.dashboardPage .dashboardPage-trainerRole {
  width: 100%;
  text-align: center;
  margin-top: 50px;
}

.dashboardPage .dashboardPage-statisticsContainer {
  width: 100%;
  overflow-y: scroll;
  position: relative;
}

.dashboardPage .dashboardPage-statisticsContainer .lds {
  position: absolute;
  top: calc(50%);
  left: calc(50%);
}

.dashboardPage .dashboardStatistics {
  padding: 24px;
}

.dashboardStatistics .chartStatistics {
  display: flex;
  gap: 24px;
  margin-bottom: 24px;
}

.dashboardStatistics .chartStatistics .card {
  width: 100%;
}

.dashboardStatistics .dashboardStatistics-cards {
  display: flex;
  gap: 24px;
  justify-content: space-between;
}

.dashboardStatistics .dashboardStatistics-cards .card {
  align-items: flex-start;
  gap: 24px;
}

.dashboardStatistics .dashboardStatistics-cards .card span {
  color: var(--color-blue-800);
  font-size: 22px;
  font-weight: 400;
}

@media only screen and (max-width: 600px) {
  .dashboardPage-mainContent {
    flex-direction: column;
    height: auto;
  }
  .dashboardStatistics .chartStatistics {
    flex-direction: column;
  }
  .dashboardPage .dashboardPage-statisticsContainer {
    width: 100vw;
  }
  .dashboardStatistics .dashboardStatistics-cards {
    flex-direction: column;
  }
}
