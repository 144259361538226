.notification {
  max-width: 600px;
  width: 100%;
  padding: 20px;
  position: relative;
  font-family: 'Karla', sans-serif;
  font-size: 1rem;
  border-radius: 10px;
  overflow: hidden;
  margin: 0 0 10px 0;
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.03);
  cursor: pointer;
  user-select: none;
}

.notification.error {
  background-color: #e12626;
  color: #fdfdfd;
}

.notification.success {
  background-color: #0caa1b;
  color: #fdfdfd;
}

.notification.warning {
  background-color: #e1aa1b;
  color: #fdfdfd;
}

.notification.info {
  background-color: #23aac5;
  color: #fdfdfd;
}

.notification .progress {
  position: absolute;
  bottom: 0;
  height: 5px;
  background-color: rgba(255, 255, 255, 0.6);
  transition: right 1s linear;
}
