.outlineButton {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  border: 1px solid #2b6a93;
  padding: 6px 12px;
  cursor: pointer;
  border-radius: 4px;
  color: var(--color-blue-700);
  font-size: 14px;
  font-family: 'Roboto', sans-serif;
  height: 32px;
}
