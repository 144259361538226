.sidebar {
  transition: transform 500ms ease;
}
.sidebarContainer {
  width: var(--widthSideBarOpen);
  transition: width 500ms ease;
  background-color: var(--color-white);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  border-right: 1px solid var(--color-light-gray);
  height: 100%;
}
.closed .sidebarContainer {
  width: var(--widthSideBarClosed);
}
.closed:hover .sidebarContainer {
  width: var(--widthSideBarOpen);
}
.closed .menuItem div {
  opacity: 0;
  width: 0;
  margin: 0 0 0 -5px;
  transition: opacity 300ms ease, margin 300ms ease;
}
.closed:hover .menuItem div {
  opacity: 1;
  margin: 0;
  width: auto;
}

.sidebarContainer .sidebar-top {
  display: flex;
  border-bottom: 1px solid var(--color-light-gray);
  width: 100%;
  justify-content: center;
  align-items: center;
  max-height: var(--heightTopBar);
  min-height: var(--heightTopBar);
}

.sidebar-topContent {
  display: flex;
  gap: 9px;
}

.sidebarContainer .sidebar-top h1 {
  font-family: "Roboto", sans-serif;
  font-size: 34px;
  font-weight: 500;
  color: var(--color-blue-800);
  align-self: flex-start;
}

.sidebarContainer .sidebar-menu {
  display: flex;
  justify-content: flex-start;
  padding: 40px 24px 10px 23px;
  /*min-width: 240px;*/
}

.sidebarContainer .sidebar-menuClosed {
  display: flex;
  justify-content: center;
  width: 100px;
  margin-top: 35px;
}

.sidebar-menuItems {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.menuItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 40px;
  gap: 10px;
  padding: 0 15px 0 15px;
  white-space: nowrap;
}

.sidebarClosed .menuItem {
  justify-content: center;
  padding: 0 !important;
}
.hamburgerButton {
  border: none;
  margin: 0;
  padding: 0;
  background-color: transparent;
  cursor: pointer;
  margin-right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.hamburgerButtonSVG {
  width: 20px;
}
.hamburgerButtonSVG .line {
  stroke: var(--color-blue-800);
}
.hamburgerButtonSVG .topLineActive {
  transform: rotate(-45deg) translate(-17.5%, 0);
  transform-origin: top right;
}
.hamburgerButtonSVG .middleLine {
  opacity: 0;
}
.hamburgerButtonSVG .bottomLineActive {
  transform: rotate(45deg) translate(-17.5%, 0);
  transform-origin: bottom right;
}

@media only screen and (max-width: 600px) {
  .sidebar {
    transform: translate(-100%);
    z-index: 50;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: white;
  }
  .sidebarContainer .sidebar-top {
    justify-content: flex-start;
    padding-left: 20px;
  }
  .closed .sidebarContainer,
  .closed:hover .sidebarContainer,
  .sidebarContainer {
    width: 100%;
    overflow-y: scroll;
  }

  .closed .menuItem div,
  .closed:hover .menuItem div {
    opacity: 1;
    margin: 0;
    width: auto;
  }
}
