.recurrence-popup .recurrence-days {
  width: 100%;
}

.recurrence-popup .recurrence-popupFormRow .recurrence-endRule {
  display: flex;
  flex-direction: column;
}

.recurrence-popup .recurrence-endRule label.inputLabel {
  margin-bottom: 14px;
}

.recurrence-popup .recurrence-days .daysContainer {
  display: flex;
  gap: 18px;
  justify-content: space-between;
  margin-top: 8px;
}

.recurrence-popup .popupOverlay .popupOverlay-mainContainer {
  padding: 20px 24px 0px 24px;
}

.recurrence-popup .recurrence-popupFormContainer .recurrence-popupFormRow {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
}

.recurrence-popup .recurrence-popupFormRow .recurrence-endRule .radioButtonContainer input.input {
  width: 75px;
  height: 38px;
  margin-left: 20px;
}

.recurrence-popup .recurrence-popupFormRow .recurrence-endRule .radioButtonContainer input.dateInput {
  width: 170px;
  height: 38px;
  margin-left: 20px;
}

.recurrence-popup .recurrence-popupFormRow .recurrence-days {
  width: 100%;
  margin-bottom: 10px;
}