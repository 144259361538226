.notificationItem {
  width: 100%;
  background-color: #fff;
  border-radius: 3px;
  border: 1px solid #e5f0f9;
  padding: 18px 16px;
  margin-bottom: 12px;
}

.notificationItem-headerContainer .notificationItem-header {
  display: flex;
  align-items: center;
  gap: 10px;
  border-bottom: 1px solid var(--color-blue-200);
  padding-bottom: 16px;
  font-size: 14px;
}

.notificationItem-header .notificationItem-headerIcon {
  display: flex;
  align-items: center;
  align-self: start;
}

.notificationItem-headerDate {
  font-size: 13px;
  font-weight: 400;
  color: #636366;
  margin-top: 5px;
}

.notificationItem .trainer,
.notificationItem .client {
  font-size: 14px;
  font-weight: 500;
  color: var(--color-blue-700);
}

.notificationItem .trainer {
  margin: 0 3px 0 0;
}

.notificationItem .client,
.notificationItem .goal {
  margin: 0 3px 0 3px;
}

.notificationItem .categories {
  display: flex;
  gap: 15px;
  padding: 16px 0;
  border-bottom: 1px solid var(--color-blue-200);
}

.notificationItem .notificationItem-buttonContainer {
  padding: 16px 0;
}

.notificationItem .notificationItem-button {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  height: 32px;
  border: none;
  border-radius: 4px;
  background-color: var(--color-blue-700);
  color: var(--color-white);
  cursor: pointer;
  padding: 12px 14px;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
}

.notificationItem .target span {
  margin: 0 3px 0 0;
}

@media only screen and (max-width: 600px) {
  .notificationItem .categories {
    flex-wrap: wrap;
  }
}
