.popup {
  z-index: 30;
}

.popup .lds-ring {
  left: 50%;
  transform: translateX(-50%);
}

.popup-formRow {
  display: flex;
  justify-content: space-between;
  gap: 15px;
}

.popup .popup-img {
  padding: 24px 24px 0 24px;
}

.popup .popup-img span {
  display: block;
  color: rgb(151 151 151);
  margin-top: 7px;
  font-style: italic;
  font-size: 11px;
}

.popup .popup-closeIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-right: 10px;
}

.popup .popup-footer {
  display: flex;
  justify-content: flex-end;
  padding: 16px;
  border-top: 1px solid rgba(0, 41, 76, 0.07);
}

.popup .popup-popupButton {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  min-height: 40px;
  border: none;
  border-radius: 4px;
  background-color: var(--color-blue-700);
  color: var(--color-white);
  cursor: pointer;
  padding: 10px 12px;
  font-size: 14px;
  font-family: 'Roboto', sans-serif;
}

.popup .popup-checkboxContainer {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 24px;
}

.popup .radioButtonContainer {
  display: flex;
  align-items: center;
  font-size: 14px;
  margin-bottom: 15px;
}

.popup .radioButtonContainer input {
  width: 16px;
  height: 16px;
  margin: 0 15px 0 0;
  cursor: pointer;
}

.popup .dividerLine {
  margin: 20px 5px;
  height: 1px;
  background-color: var(--color-blue-400);
}