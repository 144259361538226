.checkImportedClientsPage {
  display: flex;
  width: 100%;
  flex: 6;
  font-family: 'Roboto', sans-serif;
}

.checkImportedClientsPage-container {
  flex: 5;
  display: flex;
  flex-direction: column;
}

.checkImportedClientsPage-mainContent {
  height: calc(100vh - var(--heightTopBar));
  width: calc(100vw - var(--widthSideBarClosed));
  overflow-y: scroll;
}

.checkImportedClientsPage .mfTable {
  grid-template-columns: repeat(9, auto);
}