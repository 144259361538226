.clubsCard {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border-radius: 10px;
  border: 1px solid #e2e9f0;
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.03);
  width: 355px;
  font-family: "Roboto", sans-serif;
}

.clubsCard .clubsCard-body {
  padding: 16px 22px;
}

.clubsCard img {
  height: 228px;
  width: 100%;
  object-fit: cover;
  border-radius: 10px 10px 0 0;
}

.clubsCard .clubsCard-title {
  font-size: 17px;
  font-weight: 600;
  color: #000;
  margin: 0;
}

.clubsCard .clubsCard-body {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.clubsCard-body .clubsCard-text {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.clubsCard-text .clubsCard-amount {
  display: flex;
  align-items: center;
}

.clubsCard-text .clubsCard-amount .amountIcon {
  margin-right: 12px;
}

.clubsCard-amount .amount {
  margin-right: 5px;
}

.clubsCard-textLocation {
  display: flex;
  gap: 3px;
  font-size: 14px;
}

.clubsCard-text .clubsCard-textIcons {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.clubsCard-footer {
  padding: 10px 22px 12px 22px;
  display: flex;
}

.clubsCard-footer .clubsCard-button {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  height: 32px;
  border: none;
  border-radius: 4px;
  background-color: var(--color-blue-700);
  color: var(--color-white);
  cursor: pointer;
  padding: 12px 14px;
  margin: 0 2px;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
}

@media only screen and (max-width: 600px) {
  .clubsCard {
    width: 100%;
  }
}
