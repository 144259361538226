.agenda-popup .agenda-popupForm .agenda-popupFormContainer {
  display: flex;
  flex-direction: column;
}

.agenda-popup .agenda-popupFormContainer .agenda-popupFormRow {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.agenda-popup .agendaNote {
  width: 100%;
  height: 100%;
}

.agenda-popup .agendaNote label {
  color: var(--color-dark-gray);
  font-size: 14px;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
}

.agenda-popup .agendaNote textarea {
  padding: 10px;
  border-color: var(--color-blue-700);
  border-radius: 4px;
  color: var(--color-blue-900);
  width: 100%;
  height: 60px;
  resize: none;
  margin-top: 5px;
}

.agenda-popup .agenda-popupFormRow .agendaDateRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.agenda-popup .agendaDateRow .agendaDate-time {
  display: flex;
  gap: 25px;
  justify-content: space-between;
}

.agenda-popup .agendaDateRow .agendaDate-time .agendaDate-timeItem {
  display: flex;
  flex-direction: column;
}

.agenda-popup .agendaDateRow .agendaDate-time .agendaDate-timeItem .timeInput {
  width: 90px;
  height: 40px;
}

.agenda-popup .agendaDateRow .agendaDate-date input.dateInput {
  width: 200px;
}

.agenda-popup .agenda-popupFormRow .agenda-multiSelect,
.agenda-popup .agenda-popupFormRow .agenda-products,
.agenda-popup .agenda-popupFormRow .agenda-products select {
  width: 100%;
}

.agenda-popup .agenda-popupFormRow .editRecurrence-text {
  color: #98989d;
  font-style: italic;
  font-size: 15px;
  line-height: 1.4;
  margin: 0;
  letter-spacing: 0.3px;
}

.agenda-popup .agenda-popupFormRow .editRecurrence-edit {
  display: block;
  color: var(--color-blue-700);
  text-decoration: underline;
  font-weight: 500;
  cursor: pointer;
}

.agenda-popup .agenda-recurrence {
  margin-top: 10px;
  margin-bottom: 5px;
}

.agenda-popup .agenda-sendEmailConfirmation {
  margin-top: 10px;
}

.agenda-popup button:disabled {
  background-color: var(--color-blue-500);
  cursor: auto;
}

.agenda-popup .cancelAppointment-popup .popup .popup-footer .popup-buttonSection,
.agenda-popup .appointmentType-popup .popup .popup-footer .popup-buttonSection,
.agenda-popup .recurrence-popup .popup .popup-footer .popup-buttonSection {
  justify-content: flex-end;
}

@media only screen and (max-width: 600px) {
  .agenda-popupFormContainer .agenda-popupFormRow,
  .agenda-popupFormRow .agendaDateRow {
    flex-wrap: wrap;
  }
  .agendaDateRow .agendaDate-date {
    width: 100%;
  }
  .agenda-popupFormContainer .agenda-popupFormRow .inputContainer {
    width: 100%;
  }
  .agendaDateRow .agendaDate-time .agendaDate-timeItem {
    width: 45%;
  }
  .agendaDateRow .agendaDate-time .agendaDate-timeItem .timeInput {
    width: 100%;
  }
  .recurrence-days .daysContainer {
    gap: 0;
  }
  .agendaPage .recurrence-popupFormContainer .recurrence-popupFormRow {
    flex-direction: column;
  }
}
