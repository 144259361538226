.passwordFirstChangePage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.passwordFirstChangePage .popup .popup-body {
  width: 320px;
}

.passwordFirstChangePage .popup-titleContainer .popup-title {
  margin-bottom: 5px;
}

.passwordFirstChangePage .popup-inputContainer {
  margin: 24px 0 25px 0;
}
