.categoryBadge {
  display: flex;
  border-radius: 50px;
  gap: 8px;
  align-items: center;
  padding: 6px 15px;
  font-size: 14px;
  font-weight: 400;
  border: 1px solid var(--color-blue-500);
  color: var(--color-blue-700);
}

.categoryBadge svg {
  width: 18px;
  height: 18px;
  font-weight: 700;
}
