.removeClients-popup .popup {
  top: calc(50% - 150px);
}

.removeClients-popup .removeClients-clientsList {
  display: grid;
  grid-template-columns: 60px 1fr;
  row-gap: 15px;
}

.removeClients-popup h2 {
  font-size: 15px;
  font-weight: normal;
  white-space: nowrap;
  margin: 0 0 20px 0;
  padding: 0;
}

.removeClients-popup .clientsList-name,
.removeClients-popup .checkbox-label {
  font-size: 14px;
}

.removeClients-popup .cancelStatus .cancelAppointment-delete {
  display: flex;
  flex-direction: column;
}

.removeClients-popup span {
  font-size: 12px;
  font-style: italic;
  color: var(--color-dark-gray);
  margin-top: 4px;
}
