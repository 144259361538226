.moneybird-popup .popup .popup-buttonSection {
  display: flex;
}
.moneybird-popup select,
.moneybird-popup input {
  width: 350px;
}

.moneybird-popup select:disabled {
  opacity: 0.5;
}

.moneybird-popup .popup-overlay .popup-container {
  width: 410px;
}

.moneybird-popup .popup .popup-popupButton {
  margin: 0 2px;
}
.moneybird-popup .login-button {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  min-height: 40px;
  border: none;
  border-radius: 4px;
  background-color: var(--color-blue-700);
  color: var(--color-white);
  cursor: pointer;
  padding: 10px 12px;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
}

.moneybird-popup .save-button {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  border: 1px solid var(--color-blue-700);
  border-radius: 4px;
  background-color: var(--color-white);
  color: 1px solid var(--color-blue-700);
  cursor: pointer;
  padding: 5px 12px;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
}

.moneybird-popup .save-button:disabled {
  border: 1px solid grey;
  color: grey;
  cursor: default;
}
@media only screen and (max-width: 600px) {
  .moneybird-popup select,
  .moneybird-popup input {
    width: 100%;
  }
}
