.calendarControls .calendarControls-container {
  display: flex;
  padding: 20px 24px;
}

.calendarControls .calendarControls-date .dateInput {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  border: 1px solid #2b6a93;
  padding: 6px 12px;
  cursor: pointer;
  border-radius: 4px;
  color: var(--color-blue-700);
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  height: 32px;
}

.calendarControls-container .calendarControls-slide {
  display: flex;
  margin: 0 16px;
  gap: 6px;
}

.calendarControls-container .calendarControls-views {
  display: flex;
  margin-left: 16px;
  gap: 2px;
}
.calendarControls-container .calendarControls-search {
  margin-left: 16px;
}

.calendarControls .outlineButton span {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 7px;
}

.calendarControls .calendarControls-date svg,
.calendarControls .calendarControls-slide svg {
  width: 18px;
  height: 18px;
}

@media only screen and (max-width: 600px) {
  .calendarControls .calendarControls-container {
    flex-wrap: wrap;
    gap: 6px;
  }
  .calendarControls-container .calendarControls-slide {
    margin: 0;
  }
  .calendarControls-container .calendarControls-views {
    margin: 0;
    gap: 6px;
  }
  .calendarControls-container .calendarControls-search {
    margin-left: 0;
  }
}
