.badge {
  background-color: var(--color-light-brown);
  font-size: 14px;
  padding: 10px 12px;
  margin-bottom: 12px;
  height: 40px;
  border-radius: 4px;
  border: transparent;
}

@media only screen and (max-width: 600px) {
  .badge {
    width: 100%;
  }
}
