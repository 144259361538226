.editTrainerPopup .sliderPopup .sliderPopup-container {
  width: 1072px;
}

.editTrainerPopup #MFImage {
  max-width: 239px;
  max-height: 135px;
  border-radius: 4px;
}

.editTrainerPopup .editTrainerPopup-image span { 
  display: block;
  color: rgb(151 151 151);
  margin: 7px 0 10px 0;
  font-style: italic;
  font-size: 11px;
}

.editTrainerPopup .sliderPopup-cardContainer#clientSatisfaction .statistics {
  width: 100%;
}

.editTrainerPopup .sliderPopup-cardContainer .chartStatistics {
  display: flex;
  gap: 10px;
}

.editTrainerPopup .sliderPopup-cardContainer#clientSatisfaction {
  background-color: transparent;
  border: none;
  padding: 0;
}

.editTrainerPopup
  .sliderPopup-cardContainer#clientSatisfaction
  .sliderPopup-cardHeaderContainer {
  display: none;
}

.editTrainerPopup .sliderPopup-cardContainer .statisticsCard h1::after {
  content: "";
  display: inline-block;
  top: 25px;
  width: 45%;
  right: 16px;
  height: 2px;
  position: absolute;
  background-color: rgba(242, 242, 242, 1);
}

.editTrainerPopup .sliderPopup-cardContainer#clientSatisfaction .verticalbar {
  margin-top: 35px;
}

@media only screen and (max-width: 600px) {
  .editTrainerPopup .sliderPopup .sliderPopup-container {
    width: 100%;
  }
  .editTrainerPopup .sliderPopup-cardContainer .chartStatistics {
    flex-direction: column;
  }
}
