.clientsPopup-formContainer {
  display: flex;
  flex-direction: column;
}

.clientsPopup-formContainer .clientsPopup-formRow {
  display: flex;
  justify-content: space-between;
  gap: 15px;
}

.clientsPopup-form .clientsPopup-section {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.clientsPopup-sectionHeaderContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 20px 0 10px 0;
}

.clientsPopup-sectionTitle {
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-weight: 500;
}

.clientsPopup-sectionHorizontalLine {
  flex: 1;
  height: 1px;
  margin-left: 24px;
  background-color: rgba(242, 242, 242, 1);
}

.clients-popup .popupOverlay .popup .popup-img img {
  max-width: 239px;
  max-height: 135px;
  border-radius: 4px;
}
