.input {
  color: var(--color-blue-900);
  font-size: 14px;
  background-color: var(--color-blue-200);
  border: 1px solid var(--color-blue-200);
  border-radius: 4px;
  padding: 10px 10px 10px 12px;
}

.input:focus {
  background-color: transparent;
}

.input.disabled {
  background-color: var(--color-light-brown);
  cursor: default;
}