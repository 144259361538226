.checkboxContainer {
  display: flex;
  position: relative;
  cursor: pointer;
  align-items: center;
  padding-left: 25px;
}

.checkboxContainer .checkbox-label {
  color: var(--color-black);
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
}

.checkboxContainer input {
  display: none;
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 60px;
  width: 60px;
}

.checkboxContainer input:checked ~ .checkmark {
  background-color: var(--color-blue-700);
  border: var(--color-blue-700);
}

.checkboxContainer input:checked ~ .checkmark::after,
.checkboxContainer input:checked ~ .squared::after {
  display: block;
}

.checkmark {
  position: absolute;
  left: 0;
  height: 18px;
  width: 18px;
  background-color: transparent;
  border: 2px solid var(--color-blue-700);
  border-radius: 2px;
}

.checkmark::after {
  content: '';
  position: absolute;
  display: none;
}

.checkboxContainer .checkmark::after {
  left: calc(50% - 2.2px);
  top: 3px;
  width: 3px;
  height: 8px;
  border: 1px solid #fff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.checkboxContainer input:checked ~ .squared {
  border-color: var(--color-blue-700);
}

.squared {
  position: absolute;
  top: calc(50% -7px);
  left: 0;
  height: 18px;
  width: 18px;
  background-color: transparent;
  border: 2px solid #aaaaaa;
  border-radius: 2px;
}

.squared::after {
  content: '';
  position: absolute;
  display: none;
}

.squared::after {
  left: calc(50% - 4px);
  top: calc(50% - 4px);
  width: 8px;
  height: 8px;
  background-color: var(--color-blue-700);
}
