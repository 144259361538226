.cancelAppointment-popup .cancelAppointment-popupFormContainer .cancelUpdateType p {
  font-size: 15px;
  margin: 0 0 20px 0;
}

.cancelAppointment-popup .popup .radioButtonContainer  {
  align-items: flex-start;
}

.cancelAppointment-popup .cancelStatus .radioButtonContainer .cancelAppointment-delete {
  display: flex;
  flex-direction: column;
}

.cancelAppointment-popup .cancelStatus .radioButtonContainer .cancelAppointment-delete span {
  font-size: 12px;
  font-style: italic;
  color: var(--color-dark-gray);
  margin-top: 4px;
}