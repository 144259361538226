.client-weightlogs-popup .clientCaloriesAndClientGoalContainer {
  display: grid;
  grid-template-columns: 2fr 3fr;
  grid-gap: 20px;
}

.client-weightlogs-popup .clientgoalWrapper,
.client-weightlogs-popup .clientCaloriesWrapper {
  display: block;
}

.client-weightlogs-popup .clientgoalContainer,
.client-weightlogs-popup .clientCaloriesContainer,
.client-weightlogs-popup .clientgoalHeader,
.client-weightlogs-popup .clientCaloriesHeader {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin: 10px 0 20px 0;
}
.client-weightlogs-popup .clientgoalContainer .clientgoalItem,
.client-weightlogs-popup .clientCaloriesContainer .clientCaloriesItem {
  text-align: center;
}

.client-weightlogs-popup .clientgoalContainer .clientgoalItem p,
.client-weightlogs-popup .clientCaloriesContainer .clientCaloriesItem p {
  margin: 10px 0 0 0;
  font-size: 0.8rem;
}
.client-weightlogs-popup .addClientGoalButton {
  margin: 10px 0 0 0;
}

.client-weightlogs-popup .clientgoalContainer input,
.client-weightlogs-popup .clientgoalContainer .disabledInput,
.client-weightlogs-popup .clientCaloriesContainer input,
.client-weightlogs-popup .clientCaloriesContainer .disabledInput {
  width: 100% !important;
  border: 1px var(--color-blue-400) solid;
  margin: 5px 0;
  border-radius: 5px;
  padding: 6px 11px;
  transition: 200ms ease background-color;
  background: none;
  width: 100%;
  font-size: 1.2rem;
  color: black;
  text-align: center;
  font-family: "Roboto", sans-serif;
}
.client-weightlogs-popup .clientgoalContainer .buttonContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  margin: 0 !important;
}
.client-weightlogs-popup .weightlogsHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0 0 0;
  font-weight: 500;
}

.client-weightlogs-popup .addWeightLogButton {
  border: none;
  cursor: pointer;
  padding: 5px 20px;
  margin: 0;
  border-radius: 4px;
  background-color: var(--color-blue-700);
  color: white;
  border: 1px var(--color-blue-700) solid;
}

.client-weightlogs-popup .title {
  font-weight: 500;
  margin: 10px 0 0 0;
  display: inline-block;
}
.client-weightlogs-popup .clientgoalHeader .title,
.client-weightlogs-popup .clientCaloriesHeader .title {
  margin: 0 0 0 0;
}
.client-weightlogs-popup .weekContainer {
  margin: 10px 0 20px 0;
}

.client-weightlogs-popup .weekContainer .weekHeader {
  background-color: lightblue;
  display: flex;
  justify-content: space-between;
  padding: 5px 10px;
  border-radius: 5px;
  margin: 0 0 5px 0;
}
.client-weightlogs-popup .weekContainer .weekHeader p {
  margin: 0;
}
.client-weightlogs-popup .weekContainer .weekHeader .weekText {
  font-weight: 500;
}
.client-weightlogs-popup .dayContainer {
  border: 1px var(--color-blue-400) solid;
  margin: 5px 0;
  border-radius: 5px;
  padding: 6px 11px;
  transition: 200ms ease background-color;
  background: none;
  width: 100%;
  text-align: left;
  font-family: "Roboto", sans-serif;
  font-size: 1rem;
}
.client-weightlogs-popup .dayContainer:disabled {
  color: black;
}
.client-weightlogs-popup .dayContainer:hover {
  cursor: pointer;
  border: 2px var(--color-blue-400) solid;
  padding: 5px 10px;
}

.client-weightlogs-popup .dayHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.client-weightlogs-popup .dayHeader .weightInput {
  border: none;
  background: none;
  height: 40px;
  font-size: 1rem;
  margin: 0 10px 0 0;
  padding: 5px;
  color: black;
  border: 1px solid black;
  width: 75px;
}

.client-weightlogs-popup .noteContainer {
  margin: 20px 0 0 0;
}
.client-weightlogs-popup .noteInput {
  margin: 10px 0 10px 0;
  width: 100% !important;
  font-size: 1rem;
  height: 40px;
}

.client-weightlogs-popup .buttonContainer {
  display: flex;
  justify-content: center;
  margin: 0;
}
.client-weightlogs-popup .buttonContainer .saveButton,
.client-weightlogs-popup .buttonContainer .cancelButton {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  cursor: pointer;
  padding: 5px 10px;
  margin: 0;
  border-radius: 4px;
  background-color: white;
  margin: 0 5px;
  border: 1px var(--color-blue-700) solid;
}
.client-weightlogs-popup .clientgoalContainer .saveButton,
.client-weightlogs-popup .clientgoalContainer .cancelButton {
  margin: 5px 0 5px 0;
}
.client-weightlogs-popup .buttonContainer .saveButton,
.client-weightlogs-popup .clientgoalContainer .saveButton {
  color: white;
  background-color: var(--color-blue-700);
}
