.productsPage {
  display: flex;
  width: 100%;
  flex: 6;
  font-family: 'Roboto', sans-serif;
}

.productsPage-container {
  flex: 5;
  display: flex;
  flex-direction: column;
}

.productsPage-mainContent {
  height: calc(100vh - var(--heightTopBar));
  width: calc(100vw - var(--widthSideBarClosed));
  overflow-y: scroll;
}

.productsPage .productsPage-trainerRole {
  width: 100%;
  text-align: center;
  margin-top: 50px;
}

/* Product Popup */
.productsPage .popup-overlay .popup-body {
  overflow: hidden;
  margin-bottom: 10px;
}

.productsPage .products-popupForm .products-popupFormContainer {
  display: flex;
  flex-direction: column;
}

.productsPage .products-popupFormContainer .products-popupFormRow {
  display: flex;
  justify-content: space-between;
  gap: 15px;
}

.productsPage .popup .popup-buttonSection {
  display: flex;
  justify-content: space-between;

  /* TODO REMOVE WHEN IT IS POSSIBLE TO REMOVE A PRODUCT */
  justify-content: flex-end;
  width: 100%;
}

.products-popup .popup .popup-footer .popup-popupButton.button {
  background-color: transparent;
  color: red;
  font-size: 15px;
  font-weight: 400;
}

.productsPage .products-status .statusLabel {
  color: var(--color-dark-gray);
  font-size: 14px;
  font-weight: 500;
}

.productsPage .products-status .checkboxContainer,
.productsPage .products-status {
  margin-top: 10px;
}

.productsPage .popup-overlay .popup-container {
  width: 410px;
}

.productsPage .popup .products-popupFormContainer input {
  width: 100%;
}

.productsPage .popup select,
.productsPage .popup input {
  width: 170px;
  min-height: 40px;
}

.productsPage .mfTable {
  grid-template-columns: repeat(5, 1fr) 70px;
}

@media only screen and (max-width: 600px) {
  .productsPage-mainContent {
    height: auto;
    width: 100vw;
  }

  .productsPage .products-popupFormContainer .products-popupFormRow {
    flex-direction: column;
    gap: 0;
  }
  .productsPage .popup select,
  .productsPage .popup input {
    width: 100%;
  }
}
