.pagination {
  display: flex;
  justify-content: center;
}

.pageSizeContainer {
  text-align: center;
  margin-top: 5px;
}

.pageSizeContainer .pageSize {
  display: inline-block;
  padding: 10px;
  text-decoration: underline;
  cursor: pointer;
}

.paginationItem {
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  color: var(--color-blue-700);
  border: 2px solid var(--color-blue-700);
  padding: 10px 15px;
  border-radius: 5%;
  height: 35px;
  width: 35px;
  position: relative;
  margin: 0 5px;
  cursor: pointer;
}

.paginationItem-dots {
  display: flex;
  align-items: center;
  color: var(--color-blue-700);
}

.paginationItem.active {
  border: 2px solid var(--color-blue-700);
  background-color: var(--color-blue-700);
  color: white;
  border-radius: 5%;
}

.next,
.prev {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  cursor: pointer;
  background-color: transparent;
  color: var(--color-blue-700);
}

.prev.disabled,
.next.disabled {
  pointer-events: none;
  color: var(--color-blue-400);
  background-color: transparent;
}

.pagination-buttonContainer {
  display: flex;
  align-items: center;
}