.editClientPopup .sm-select select,
.editClientPopup .sm-select input {
  width: 239px;
}

.editClientPopup .sliderPopup .sliderPopup-container {
  width: 1072px;
}

.editClientPopup .sliderPopup #MFImage {
  max-width: 239px;
  max-height: 135px;
  border-radius: 4px;
}

.editClientPopup .editClientPopup-image span {
  display: block;
  color: rgb(151 151 151);
  margin: 7px 0 15px 0;
  font-style: italic;
  font-size: 11px;
}

.editClientPopup .sliderPopup-buttonContainer {
  display: flex;
  gap: 10px;
  margin-top: 25px;
}

.editClientPopup .editClientPopup-products {
  display: grid;
  grid-template-columns: auto 90px;
  column-gap: 10px;
  margin-top: 10px;
}

.editClientPopup h2 {
  font-size: 14px;
  color: var(--color-dark-gray);
  margin: 0 0 5px 0;
  padding: 0;
  font-weight: 600;
}


@media only screen and (max-width: 600px) {
  .editClientPopup .sliderPopup .sliderPopup-container {
    width: 100%;
  }
  .editClientPopup .sm-select select,
  .editClientPopup .sm-select input {
    width: 100%;
  }
}
