.verticalbar {
  display: flex;
  padding: 20px;
  width: 100%;
}

.verticalbar-container {
  display: flex;
  gap: 2px;
  width: 100%;
  overflow: hidden;
}

.verticalbar-item {
  display: flex;
  flex-direction: column;
  text-align: center;
  transition: width 2s ease-in-out;
}

.verticalbar-meter {
  height: 24px;
  position: relative;
  background: #e5e5e5;
  overflow: hidden;
  margin-bottom: 7px;
}

.verticalbar-meter span {
  display: block;
  height: 100%;
}

.progress {
  animation: progressBar 2s ease-in-out;
  animation-fill-mode: both;
}

.progress.orange {
  background-color: #ff9500;
}

.progress.red {
  background-color: #ff3b30;
}

.progress.green {
  background-color: #34c759;
}

@keyframes progressBar {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

.verticalbar-amountLabel {
  font-size: 17px;
  font-weight: 600;
  color: var(--color-blue-900);
}

.verticalbar-rangeLabel {
  font-size: 14px;
  font-weight: 300;
  color: var(--color-blue-700);
}
