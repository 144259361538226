.mfTableWrapper {
  width: 100%;
  height: 100%;
  overflow: auto;
}

.mfTableWrapper .lds-ring {
  display: block;
  margin: 50px auto;
}

.mfTable {
  display: grid;
}

.mfTable .tableRow {
  display: contents;
}

.mfTable tcxspan {
  text-decoration: none;
  pointer-events: none;
}

.mfTable .tableHeader .tableColumn,
.mfTable .tableFooter .tableColumn {
  display: flex;
  justify-content: space-between;
  align-items: center;

  font-weight: 600;
  padding: 0.875rem;
  text-align: left;
  border: 1px solid var(--color-blue-200);
  background-color: var(--color-blue-50);

  width: auto;
  white-space: nowrap;
}

@media only screen and (min-width: 601px) {
  .mfTable .tableHeader .tableColumn,
  .mfTable .tableFooter .tableColumn {
    position: sticky;
  }
  .mfTable .tableHeader .tableColumn {
    top: -1px;
  }

  .mfTable .tableFooter .tableColumn {
    bottom: -1px;
  }
}

.mfTable .tableColumn {
  padding: 0.875rem;
  border: 0.5px solid var(--color-blue-200);

  width: auto;
  white-space: nowrap;
}

.mfTable .arrowDown {
  border: none;
  background: none;
  cursor: pointer;
  padding: 0;
  margin-left: auto;
  position: relative;
}

.mfTable .calculationSetter {
  position: absolute;
  display: flex;
  flex-direction: column;
  bottom: 20px;
  right: 0px;
  background-color: #fff;
  border-radius: 9px;
  border: 1px solid var(--color-blue-200);
  box-shadow: 0px 0px 15px 10px rgba(0, 0, 0, 0.15);
}

.mfTable .calculationSetter button {
  border: none;
  background: none;
  width: 100%;
  height: 20%;
  font-size: 14px;
  padding: 15px;
  text-align: left;
  cursor: pointer;
  font-family: "Roboto", sans-serif;
  color: var(--color-blue-700);
}

.mfTable .calculationSetter button:not(:last-child) {
  border-bottom: 1px solid var(--color-blue-200);
}

.mfTable .tableHeader .table-icons {
  display: flex;
  align-items: center;
  gap: 5px;
}

.mfTable .table-icons .table-iconClose {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.mfTable .tableRow:hover .tableColumn {
  background-color: var(--color-blue-50);
}

.mfTablePagination {
  margin: 70px 0;
}