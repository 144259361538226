.dayButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 45px;
  height: 45px;
  padding: 8px;
  border-radius: 50%;
  color: var(--color-blue-900);
  cursor: pointer;
}

.dayButtonNotActive {
  background-color: var(--color-blue-200);
}

.dayButtonActive {
  background-color: var(--color-blue-500);
}

.dayButton span {
  font-size: 15px;
  font-weight: 500;
}
