.trainersPage {
  display: flex;
  width: 100%;
  flex: 6;
  font-family: "Roboto", sans-serif;
}

.trainersPage-container {
  flex: 5;
  display: flex;
  flex-direction: column;
}

.trainersPage .trainersPage-trainerRole {
  width: 100%;
  text-align: center;
  margin-top: 50px;
}

.trainersPage-mainContent {
  height: calc(100vh - var(--heightTopBar));
  width: calc(100vw - var(--widthSideBarClosed));
  display: flex;
  flex-direction: row;
}
.trainersPage .mfTable {
  grid-template-columns: repeat(5, auto) 70px;
}

@media only screen and (max-width: 600px) {
  .trainersPage-mainContent {
    flex-direction: column;
    height: auto;
    width: 100vw;
  }
}
