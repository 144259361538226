.popupOverlay .popupOverlay-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(27, 27, 27, 0.4);
  z-index: 15;
}

.popupOverlay .popup {
  position: absolute;
  top: 5vh;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 10px;
  font-family: "Roboto", sans-serif;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.15);
}

.popupOverlay .popup-container {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  width: 100%;
  max-height: 90vh;
  border-radius: 10px;
  min-width: 400px;
}

.popupOverlay .popup-titleContainer {
  display: flex;
  justify-content: space-between;
  padding: 22px 16px;
  border-bottom: 1px solid rgba(0, 41, 76, 0.07);
  font-size: 16px;
  font-weight: 500;
}

.popupOverlay .popup-body {
  overflow-y: scroll;
}

.popupOverlay .popupOverlay-mainContainer {
  padding: 20px 24px;
}

@media only screen and (max-width: 600px) {
  .popupOverlay .popup {
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    transform: translate(0);
    border-radius: 0;
    position: fixed;
  }
  .popupOverlay .popup-container {
    border-radius: 0;
    width: 100vw;
    min-height: -webkit-fill-available;
    height: -webkit-fill-available;
    min-width: 0;
  }
  .popupOverlay .popup-img {
    width: 100%;
  }
  .popupOverlay .popup-img img {
    width: 90%;
  }
}
