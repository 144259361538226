.agendaPage {
  flex: 6;
  display: flex;
  width: 100%;
  font-family: "Roboto", sans-serif;
}

.agendaPage .agendaPage-main {
  flex: 5;
  display: flex;
  flex-direction: column;
}

.agendaPage .agendaPage-mainContent {
  height: calc(100vh - var(--heightTopBar));
  width: calc(100vw - var(--widthSideBarClosed));
  overflow-y: scroll;
  display: flex;
  flex-direction: row;
}

.agendaPage .popup select {
  width: 200px;
}

/* Agenda Popup */
.agendaPage .agenda-popup .popup .popup-body {
  width: 500px;
}

.agendaPage .popup .popup-footer .popup-buttonSection {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.agendaPage .removeClients-popup .popup .popup-footer .popup-buttonSection {
  justify-content: flex-end;
}

.agenda-popup .popup .popup-footer .popup-popupButton.button {
  background-color: transparent;
  color: red;
  font-size: 15px;
  font-weight: 400;
}

@media only screen and (max-width: 600px) {
  .agendaPage .agendaPage-mainContent {
    flex-direction: column;
    height: auto;
    width: 100vw;
  }
  .agendaPage .agenda-popup .popup .popup-body {
    width: 100%;
    height: auto;
  }
  .agendaPage .popup select,
  .agendaPage .popup input {
    width: 100%;
  }
}
