.collapseContainer {
  margin-bottom: 10px;
}

.collapseContainer.empty {
  pointer-events: none;
  opacity: 0.6;
}

.collapseContainer .collapse-menu {
  display: flex;
  align-items: center;
  gap: 3px;
}

.collapseContainer .collapse-options {
  overflow: hidden;
  transition: height ease 0.3s;
  background-color: transparent;
}

.collapseContainer .collapse-content {
  display: flex;
  flex-direction: column;
  gap: 12px;
  border-radius: 5px;
  padding-left: 12px;
  margin: 12px 0 10px 25px;
}

.collapseContainer svg {
  cursor: pointer;
}
