.invoicesPage {
  display: flex;
  width: 100%;
  flex: 6;
  font-family: "Roboto", sans-serif;
}

.invoicesPage-container {
  flex: 5;
  display: flex;
  flex-direction: column;
}

.invoicesPage-mainContent {
  height: calc(100vh - var(--heightTopBar));
  width: calc(100vw - var(--widthSideBarClosed));
  display: flex;
  flex-direction: row;
}

.invoicesPage .invoicesPage-trainerRole {
  width: 100%;
  text-align: center;
  margin-top: 50px;
}

.status {
  font-size: 14px;
  color: white;
  padding: 9px 15px;
  border-radius: 24px;
}

.statusOpen {
  background-color: #007aff;
}

.statusPending {
  background-color: #af52de;
}

.statusPaid {
  background-color: #34c759;
}

.statusLate {
  background-color: #ff9500;
}

.statusReminded {
  background-color: #c1c1c1;
}

.statusUncollectable {
  background-color: #f13f3f;
}

.invoicesPage .mfTable {
  grid-template-columns: repeat(7, auto);
}

@media only screen and (max-width: 600px) {
  .invoicesPage-mainContent {
    flex-direction: column;
    height: auto;
    width: 100vw;
  }
}
