.passwordRequestPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.passwordRequestPage .popup .popup-body {
  width: 320px;
}

.passwordRequestPage .popup-inputContainer {
  margin: 24px 0 25px 0;
}

.passwordRequestPage span {
  font-size: 14px;
}

.passwordRequestPage .popup-titleContainer .popup-title {
  margin-bottom: 25px;
}