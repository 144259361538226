.doughnutChart {
  position: relative;
}

.doughnutChart .doughnutChart-container {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 200px;
}

.doughnutChart .dougnutChart-label {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  gap: 2px;
  position: absolute;
  bottom: 25%;
  width: 100%;
}

.dougnutChart-label #label-rating {
  color: var(--color-blue-900);
  font-size: 17px;
  font-family: 'Roboto', sans-serif;
  font-weight: 600;
}

.dougnutChart-label span {
  color: var(--color-blue-800);
  font-weight: 400;
  text-align: center;
  font-size: 15px;
}
