.notificationContainer {
  position: fixed;
  left: 10px;
  right: 10px;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 40;
}
