.hoursPage {
  display: flex;
  width: 100%;
  flex: 6;
  font-family: "Roboto", sans-serif;
}

.hoursPage-container {
  flex: 5;
  display: flex;
  flex-direction: column;
}

.hoursPage-mainContent {
  display: flex;
  height: calc(100vh - var(--heightTopBar));
  width: calc(100vw - var(--widthSideBarClosed));
  flex-direction: row;
}

.hoursPage .hoursPage-trainerRole {
  width: 100%;
  text-align: center;
  margin-top: 50px;
}

.hoursPage .mfTable {
  grid-template-columns: repeat(4, auto);
}

@media only screen and (max-width: 600px) {
  .hoursPage-mainContent {
    flex-direction: column;
    height: auto;
    width: 100vw;
  }
}
