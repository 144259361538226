/**
 * We know this stylesheet will always be included.
 * And since we want to avoid inline styles to prevent injection,
 * we'll just put the global styles here.
 **/

@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap");

:root {
  --color-red-500: #e27e7e;
  --color-green-500: #b0e27e;
  --color-gray-500: #E6E6E6;
  --color-blue-500: #7eb5e2;
  --color-blue-700: #2b6a93;
  --color-blue-800: #00486e;
  --color-blue-900: #00294c;
  --color-blue-400: #bedaf0;
  --color-blue-200: #e5f0f9;
  --color-blue-50: #f7fafb;
  --color-dark-gray: #1c1c1e;
  --color-light-gray: #e0e0e0;
  --color-black: #111111;
  --color-white: #fff;
  --color-light-brown: #f2f2f2;
  --color-good: #34c759;
  --color-mediocre: #ff9500;
  --color-bad: #ff3b30;
  --heightTopBar: 72px;
  --widthSideBarOpen: 220px;
  --widthSideBarClosed: 100px;
  --widthFilterBar: 240px;
  --border-light-gray: 1px solid #e0e0e0;
}

html,
body {
  margin: 0;
  border: 0;
  padding: 0;
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
}

* {
  box-sizing: border-box;
}

*::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: none;
}

*::-webkit-scrollbar-thumb {
  background-color: var(--color-blue-400);
  border-radius: 4px;
}

*::-webkit-scrollbar-button {
  display: none;
}

.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.app-pageContainer {
  flex-grow: 1;
  display: flex;
  position: relative;
}

.active {
  background-color: var(--color-blue-200);
  border-radius: 40px;
}

a {
  font-family: "Roboto", sans-serif;
  color: var(--color-blue-700);
  font-size: 13px;
  text-decoration: none;
}

@media only screen and (max-width: 600px) {
  .app {
    min-height: -webkit-fill-available;
  }

  html,
  body {
    height: -webkit-fill-available;
  }
}
