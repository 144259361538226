.trainers-popupFormContainer .trainers-popupFormRow {
  display: flex;
  justify-content: space-between;
  gap: 15px;
}

.trainers-popupFormContainer .trainers-popupFormRow .inputContainer,
.trainers-popupFormContainer .trainers-popupFormRow .inputContainer input,
.trainers-popupFormContainer .trainers-popupFormRow .inputContainer select {
  width: 100%;
}

.trainers-popup .popupOverlay .popup .popup-img img {
  max-width: 239px;
  max-height: 135px;
  border-radius: 4px;
}

@media only screen and (max-width: 600px) {
  .trainers-popupFormContainer .trainers-popupFormRow {
    flex-direction: column;
    gap: 0;
  }
}
