.loginPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow-x: hidden;
  overflow-y: auto;
}

.loginPage .popup-checkboxContainer {
  margin: 34px 0 34px 0;
}

.loginPage .popup .popup-linkContainer {
  text-align: center;
}

.loginPage .popup-inputContainer {
  margin-bottom: 19px;
}

.loginPage .popup-inputContainer input {
  width: 290px;
  height: 40px;
}
