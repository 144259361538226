.sliderPopup .sliderPopup-backgroundContainer {
  background-color: rgba(27, 27, 27, 0.4);
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  margin: 0;
  padding: 0;
  border: none;
  z-index: 4;
}

.sliderPopup .sliderPopup-container {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  z-index: 5;
}

.sliderPopup .sliderPopup-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: var(--heightTopBar);
  border-bottom: 1px solid var(--color-light-gray);
  padding: 12px 16px;
}

.sliderPopup .sliderPopup-headerLeft {
  font-size: 15px;
  font-weight: 600;
  color: var(--color-dark-gray);
}

.sliderPopup .sliderPopup-headerRight {
  display: flex;
  gap: 18px;
}

.sliderPopup .sliderPopup-mainContainer {
  display: flex;
  flex-direction: row;
  height: calc(100vh - var(--heightTopBar));
}

.sliderPopup .sliderPopup-sidebar {
  padding: 18px;
  height: 100%;
  background-color: rgba(253, 253, 253, 1);
  box-shadow: 1px 0px 0px 0px rgba(242, 242, 242, 1);
}

.sliderPopup .sliderPopup-sideBarLink {
  margin-bottom: 8px;
}

.sliderPopup .sliderPopup-main {
  background-color: rgba(247, 250, 251, 1);
  flex: 1;
  padding: 24px;
  overflow-y: scroll;
}

.sliderPopup .sliderPopup-cardHeaderContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 15px;
}

.sliderPopup .sliderPopup-cardTitle {
  margin: 0;
  padding: 0;
  font-size: 17px;
  font-weight: 400;
}

.sliderPopup .sliderPopup-cardHorizontalLine {
  flex: 1;
  height: 2px;
  margin-left: 24px;
  background-color: rgba(242, 242, 242, 1);
}

.sliderPopup .form-halfWidth {
  width: 49%;
}

.sliderPopup .form-quarterWidth {
  width: 23.5%;
}

.sliderPopup .form-rowOfTwo {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}

.sliderPopup .sliderPopup-button,
.sliderPopup .sliderPopup-buttonContainer #passwordRequest {
  display: flex;
  align-items: center;
  min-height: 40px;
  border: none;
  border-radius: 4px;
  background-color: var(--color-blue-700);
  color: var(--color-white);
  cursor: pointer;
  padding: 10px 12px;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
}

.sliderPopup .sliderPopup-buttonContainer a {
  display: flex;
  align-items: center;
  min-height: 40px;
  width: 250px;
  border: none;
  border-radius: 4px;
  background-color: var(--color-blue-700);
  color: var(--color-white);
  cursor: pointer;
  padding: 10px 12px;
  font-size: 14px;
}

.sliderPopup .button-outline {
  background-color: transparent;
  color: var(--color-blue-700);
  border: 1px solid var(--color-blue-700);
}

.sliderPopup .sliderPopup-buttonContainer {
  margin-top: 25px;
}
@media only screen and (max-width: 600px) {
  .sliderPopup {
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    transform: translate(0);
    border-radius: 0;
    position: fixed;
  }
  .sliderPopup .sliderPopup-header {
    flex-wrap: wrap;
    height: auto;
    flex-direction: column-reverse;
  }
  .sliderPopup .sliderPopup-headerLeft {
    margin-top: 10px;
    width: 100%;
    text-align: center;
  }
  .sliderPopup .sliderPopup-sidebar {
    display: none;
  }
  .sliderPopup .sliderPopup-main {
    padding: 10px;
  }
  .sliderPopup .form-rowOfTwo {
    flex-direction: column;
  }
  .sliderPopup .form-halfWidth {
    width: 100%;
  }
  .sliderPopup .form-quarterWidth {
    width: 100%;
  }
  .sliderPopup .sliderPopup-mainContainer {
    height: auto;
    overflow: scroll;
  }
}
