@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap);
.authenticationPage {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: url(/static/media/login-background.c1938e65.jpg);
  background-size: cover;
  background-position: center;
}

.authenticationPage::before {
  position: absolute;
  content: '';
  display: block;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  -webkit-filter: blur(1px) brightness(0.8);
          filter: blur(1px) brightness(0.8);
  background-image: url(/static/media/login-background.c1938e65.jpg);
  background-size: cover;
  background-position: center;
}

.authenticationPage #MFLogo {
  position: absolute;
  top: calc(50% - 300px);
  left: calc(50% - 33px);
}

.authenticationPage .popup {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.authenticationPage .popup-titleContainer .popup-title {
  font-size: 24px;
  font-weight: 500;
}

.authenticationPage .popup-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0 auto 0 auto;
  background-color: #ffffff;
  border-radius: 10px;
  font-family: 'Roboto', sans-serif;
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.1);
  padding: 26px 30px;
}

.authenticationPage .popup-inputContainer {
  display: flex;
  flex-direction: column;
}

.authenticationPage .popup-popupButton {
  font-size: 15px;
  font-weight: 500;
  width: 100%;
}

.authenticationPage .popup-popupButton.disabled {
  background-color: #7db4e1;
  cursor: default;
}

.authenticationPage .popup-linkContainer {
  margin-top: 15px;
}

.input {
  color: var(--color-blue-900);
  font-size: 14px;
  background-color: var(--color-blue-200);
  border: 1px solid var(--color-blue-200);
  border-radius: 4px;
  padding: 10px 10px 10px 12px;
}

.input:focus {
  background-color: transparent;
}

.input.disabled {
  background-color: var(--color-light-brown);
  cursor: default;
}
.inputLabel {
  color: var(--color-dark-gray);
  font-size: 14px;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  margin-bottom: 5px;
}

.checkboxContainer {
  display: flex;
  position: relative;
  cursor: pointer;
  align-items: center;
  padding-left: 25px;
}

.checkboxContainer .checkbox-label {
  color: var(--color-black);
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
}

.checkboxContainer input {
  display: none;
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 60px;
  width: 60px;
}

.checkboxContainer input:checked ~ .checkmark {
  background-color: var(--color-blue-700);
  border: var(--color-blue-700);
}

.checkboxContainer input:checked ~ .checkmark::after,
.checkboxContainer input:checked ~ .squared::after {
  display: block;
}

.checkmark {
  position: absolute;
  left: 0;
  height: 18px;
  width: 18px;
  background-color: transparent;
  border: 2px solid var(--color-blue-700);
  border-radius: 2px;
}

.checkmark::after {
  content: '';
  position: absolute;
  display: none;
}

.checkboxContainer .checkmark::after {
  left: calc(50% - 2.2px);
  top: 3px;
  width: 3px;
  height: 8px;
  border: 1px solid #fff;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}

.checkboxContainer input:checked ~ .squared {
  border-color: var(--color-blue-700);
}

.squared {
  position: absolute;
  top: calc(50% -7px);
  left: 0;
  height: 18px;
  width: 18px;
  background-color: transparent;
  border: 2px solid #aaaaaa;
  border-radius: 2px;
}

.squared::after {
  content: '';
  position: absolute;
  display: none;
}

.squared::after {
  left: calc(50% - 4px);
  top: calc(50% - 4px);
  width: 8px;
  height: 8px;
  background-color: var(--color-blue-700);
}

.popup {
  z-index: 30;
}

.popup .lds-ring {
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}

.popup-formRow {
  display: flex;
  justify-content: space-between;
  grid-gap: 15px;
  gap: 15px;
}

.popup .popup-img {
  padding: 24px 24px 0 24px;
}

.popup .popup-img span {
  display: block;
  color: rgb(151 151 151);
  margin-top: 7px;
  font-style: italic;
  font-size: 11px;
}

.popup .popup-closeIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-right: 10px;
}

.popup .popup-footer {
  display: flex;
  justify-content: flex-end;
  padding: 16px;
  border-top: 1px solid rgba(0, 41, 76, 0.07);
}

.popup .popup-popupButton {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  min-height: 40px;
  border: none;
  border-radius: 4px;
  background-color: var(--color-blue-700);
  color: var(--color-white);
  cursor: pointer;
  padding: 10px 12px;
  font-size: 14px;
  font-family: 'Roboto', sans-serif;
}

.popup .popup-checkboxContainer {
  display: flex;
  flex-direction: column;
  grid-gap: 24px;
  gap: 24px;
  margin-top: 24px;
}

.popup .radioButtonContainer {
  display: flex;
  align-items: center;
  font-size: 14px;
  margin-bottom: 15px;
}

.popup .radioButtonContainer input {
  width: 16px;
  height: 16px;
  margin: 0 15px 0 0;
  cursor: pointer;
}

.popup .dividerLine {
  margin: 20px 5px;
  height: 1px;
  background-color: var(--color-blue-400);
}
.loginPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow-x: hidden;
  overflow-y: auto;
}

.loginPage .popup-checkboxContainer {
  margin: 34px 0 34px 0;
}

.loginPage .popup .popup-linkContainer {
  text-align: center;
}

.loginPage .popup-inputContainer {
  margin-bottom: 19px;
}

.loginPage .popup-inputContainer input {
  width: 290px;
  height: 40px;
}

select {
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  color: var(--color-blue-900);
  background-color: rgba(125, 180, 225, 0.2);
  border: 1px solid #e2e9f0;
  border-radius: 4px;
  padding: 0 10px 0 10px;
  width: 100%;
  min-height: 40px;
}

.dateInput {
  color: var(--color-blue-900);
  font-size: 14px;
  font-weight: 600;
  background-color: var(--color-blue-200);
  border: 1px solid var(--color-blue-200);
  border-radius: 4px;
  padding: 10px 10px 10px 12px;
}

.inputContainer {
  display: flex;
  flex-direction: column;
  margin: 10px 0;
}

.popupOverlay .popupOverlay-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(27, 27, 27, 0.4);
  z-index: 15;
}

.popupOverlay .popup {
  position: absolute;
  top: 5vh;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  border-radius: 10px;
  font-family: "Roboto", sans-serif;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.15);
}

.popupOverlay .popup-container {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  width: 100%;
  max-height: 90vh;
  border-radius: 10px;
  min-width: 400px;
}

.popupOverlay .popup-titleContainer {
  display: flex;
  justify-content: space-between;
  padding: 22px 16px;
  border-bottom: 1px solid rgba(0, 41, 76, 0.07);
  font-size: 16px;
  font-weight: 500;
}

.popupOverlay .popup-body {
  overflow-y: scroll;
}

.popupOverlay .popupOverlay-mainContainer {
  padding: 20px 24px;
}

@media only screen and (max-width: 600px) {
  .popupOverlay .popup {
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    -webkit-transform: translate(0);
            transform: translate(0);
    border-radius: 0;
    position: fixed;
  }
  .popupOverlay .popup-container {
    border-radius: 0;
    width: 100vw;
    min-height: -webkit-fill-available;
    height: -webkit-fill-available;
    min-width: 0;
  }
  .popupOverlay .popup-img {
    width: 100%;
  }
  .popupOverlay .popup-img img {
    width: 90%;
  }
}

.trainers-popupFormContainer .trainers-popupFormRow {
  display: flex;
  justify-content: space-between;
  grid-gap: 15px;
  gap: 15px;
}

.trainers-popupFormContainer .trainers-popupFormRow .inputContainer,
.trainers-popupFormContainer .trainers-popupFormRow .inputContainer input,
.trainers-popupFormContainer .trainers-popupFormRow .inputContainer select {
  width: 100%;
}

.trainers-popup .popupOverlay .popup .popup-img img {
  max-width: 239px;
  max-height: 135px;
  border-radius: 4px;
}

@media only screen and (max-width: 600px) {
  .trainers-popupFormContainer .trainers-popupFormRow {
    flex-direction: column;
    grid-gap: 0;
    gap: 0;
  }
}

.agendaPage .showProfile .popup .popup-footer .popup-buttonSection {
  justify-content: flex-end;
}

.topbarContainerSpaceBetween {
  display: flex;
  height: var(--heightTopBar);
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: var(--color-white);
  border-bottom: 1px solid var(--color-light-gray);
  font-family: "Roboto", sans-serif;
  font-size: 1rem;
}

.topbarContainerFlexEnd {
  display: flex;
  height: var(--heightTopBar);
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  background-color: var(--color-white);
  border-bottom: 1px solid var(--color-light-gray);
  padding: 0 24px;
  font-family: "Roboto", sans-serif;
  font-size: 1rem;
}

.topbar-right {
  flex: 1 1;
  margin: 0 !important;
  height: 100%;
  max-width: var(--widthFilterBar);
  min-width: var(--widthFilterBar);
  max-width: var(--widthFilterBar);
}

.topbar-right .topbar-rightContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  height: 100%;
  width: 100%;
}

.topbar-right h2 {
  padding: 0;
  margin: 0;
  font-size: 22px;
  font-weight: 500;
  color: var(--color-blue-800);
}

.childrenContainer {
  flex: 5 1;
}

.topbar-user {
  display: flex;
  flex-direction: row;
  grid-gap: 20px;
  gap: 20px;
  align-items: center;
  color: var(--color-blue-700);
  padding-right: 40px;
  font-weight: 500;
}

.topbar-user img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  object-fit: cover;
}

.topbar-user .dropdown-container .dropdown {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.dropdown-container {
  position: relative;
}

.dropdown-content {
  position: absolute;
  top: 37px;
  right: 6px;
  width: 180px;

  background-color: var(--color-white);
  z-index: 4;
  border-radius: 10px;
  color: var(--color-blue-700);
  font-size: 14px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);

  display: flex;
  flex-direction: column;
}

.dropdown-contentItem {
  height: 100%;
}

.dropdown-contentItem a {
  display: flex;
  align-items: center;
  padding: 12px 0px;
  margin: 0 16px;
  grid-gap: 25px;
  gap: 25px;
  cursor: pointer;
  font-weight: 400;
}

.dropdown-content .dropdown-contentItem:not(:first-child) {
  border-top: 1px solid #e5e5ea;
}
.hamburgerButton {
  border: none;
  margin: 0;
  padding: 0;
  background-color: transparent;
  cursor: pointer;
}
.hamburgerButtonSVG {
  width: 20px;
}

.hamburgerButtonSVG .topLine {
  -webkit-transform-origin: top right;
          transform-origin: top right;
}
.hamburgerButtonSVG .bottomLine {
  -webkit-transform-origin: bottom right;
          transform-origin: bottom right;
}

@media only screen and (max-width: 600px) {
  .topbarContainerSpaceBetween {
    flex-wrap: wrap;
    min-height: var(--heightTopBar);
    height: auto;
    align-items: flex-start;
  }
  .topbarContainerSpaceBetween .topbar-right {
    order: 1;
    flex: 0 1;
  }
  .topbarContainerSpaceBetween .childrenContainer {
    order: 3;
    flex: 0 1;
  }
  .topbarContainerSpaceBetween .topbar-user {
    order: 2;
    padding-right: 15px;
  }
  .topbar-right,
  .topbar-user {
    min-width: auto;
    max-width: auto;
    min-height: var(--heightTopBar);
    align-items: center;
    display: flex;
  }
  .topbar-user {
    padding-right: 0;
  }
}

.filterbar {
  width: var(--widthFilterBar);
  height: 100%;
  background-color: #fdfdfd;
  border-right: 1px solid #f2f2f2;
  min-width: var(--widthFilterBar);
  overflow-y: scroll;
  overflow-x: hidden;
}

.filterbar .filterbar-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 20px 24px;
}

.filterbar-container .filter-section {
  border-bottom: 1px solid var(--color-light-gray);
  margin-bottom: 22px;
}

.filter-section .filter-sectionContainer {
  margin-bottom: 20px;
}
.filter-section .filter-titleContainer {
  display: flex;
  justify-content: space-between;
}
.filter-filterOpenButton {
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 10px;
  border: none;
  background-color: var(--color-blue-700);
  cursor: pointer;
  border-radius: 4px;
  color: white;
}
.filterbar h1 {
  margin-bottom: 10px;
  padding: 0;
  font-size: 15px;
  font-weight: 500;
}

.filterbar .priceFilter-header,
.filterbar .dateFilter-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-gap: 10px;
  gap: 10px;
}

.filterbar .resetFilter {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.filterbar .priceFilter .priceFilter-main {
  display: flex;
  justify-content: space-between;
  grid-gap: 15px;
  gap: 15px;
}

.filterbar .dateFilter .dateFilter-main {
  display: flex;
  flex-direction: column;
}

.filterbar .priceFilter .inputContainer {
  width: 100%;
}

.filterbar .priceFilter input {
  width: 100%;
}

@media only screen and (max-width: 600px) {
  .filterbar {
    width: 100vw;
  }
  .filter-section .filter-sectionContainer {
    margin-bottom: 5px;
  }
  .filterbar-container .filter-section {
    margin-bottom: 10px;
  }
  .filterbar .filterbar-container {
    padding: 10px 15px;
  }
}

.collapseContainer {
  margin-bottom: 10px;
}

.collapseContainer.empty {
  pointer-events: none;
  opacity: 0.6;
}

.collapseContainer .collapse-menu {
  display: flex;
  align-items: center;
  grid-gap: 3px;
  gap: 3px;
}

.collapseContainer .collapse-options {
  overflow: hidden;
  transition: height ease 0.3s;
  background-color: transparent;
}

.collapseContainer .collapse-content {
  display: flex;
  flex-direction: column;
  grid-gap: 12px;
  gap: 12px;
  border-radius: 5px;
  padding-left: 12px;
  margin: 12px 0 10px 25px;
}

.collapseContainer svg {
  cursor: pointer;
}

.sidebar {
  transition: -webkit-transform 500ms ease;
  transition: transform 500ms ease;
  transition: transform 500ms ease, -webkit-transform 500ms ease;
}
.sidebarContainer {
  width: var(--widthSideBarOpen);
  transition: width 500ms ease;
  background-color: var(--color-white);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  border-right: 1px solid var(--color-light-gray);
  height: 100%;
}
.closed .sidebarContainer {
  width: var(--widthSideBarClosed);
}
.closed:hover .sidebarContainer {
  width: var(--widthSideBarOpen);
}
.closed .menuItem div {
  opacity: 0;
  width: 0;
  margin: 0 0 0 -5px;
  transition: opacity 300ms ease, margin 300ms ease;
}
.closed:hover .menuItem div {
  opacity: 1;
  margin: 0;
  width: auto;
}

.sidebarContainer .sidebar-top {
  display: flex;
  border-bottom: 1px solid var(--color-light-gray);
  width: 100%;
  justify-content: center;
  align-items: center;
  max-height: var(--heightTopBar);
  min-height: var(--heightTopBar);
}

.sidebar-topContent {
  display: flex;
  grid-gap: 9px;
  gap: 9px;
}

.sidebarContainer .sidebar-top h1 {
  font-family: "Roboto", sans-serif;
  font-size: 34px;
  font-weight: 500;
  color: var(--color-blue-800);
  align-self: flex-start;
}

.sidebarContainer .sidebar-menu {
  display: flex;
  justify-content: flex-start;
  padding: 40px 24px 10px 23px;
  /*min-width: 240px;*/
}

.sidebarContainer .sidebar-menuClosed {
  display: flex;
  justify-content: center;
  width: 100px;
  margin-top: 35px;
}

.sidebar-menuItems {
  display: flex;
  flex-direction: column;
  grid-gap: 15px;
  gap: 15px;
}

.menuItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 40px;
  grid-gap: 10px;
  gap: 10px;
  padding: 0 15px 0 15px;
  white-space: nowrap;
}

.sidebarClosed .menuItem {
  justify-content: center;
  padding: 0 !important;
}
.hamburgerButton {
  border: none;
  margin: 0;
  padding: 0;
  background-color: transparent;
  cursor: pointer;
  margin-right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.hamburgerButtonSVG {
  width: 20px;
}
.hamburgerButtonSVG .line {
  stroke: var(--color-blue-800);
}
.hamburgerButtonSVG .topLineActive {
  -webkit-transform: rotate(-45deg) translate(-17.5%, 0);
          transform: rotate(-45deg) translate(-17.5%, 0);
  -webkit-transform-origin: top right;
          transform-origin: top right;
}
.hamburgerButtonSVG .middleLine {
  opacity: 0;
}
.hamburgerButtonSVG .bottomLineActive {
  -webkit-transform: rotate(45deg) translate(-17.5%, 0);
          transform: rotate(45deg) translate(-17.5%, 0);
  -webkit-transform-origin: bottom right;
          transform-origin: bottom right;
}

@media only screen and (max-width: 600px) {
  .sidebar {
    -webkit-transform: translate(-100%);
            transform: translate(-100%);
    z-index: 50;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: white;
  }
  .sidebarContainer .sidebar-top {
    justify-content: flex-start;
    padding-left: 20px;
  }
  .closed .sidebarContainer,
  .closed:hover .sidebarContainer,
  .sidebarContainer {
    width: 100%;
    overflow-y: scroll;
  }

  .closed .menuItem div,
  .closed:hover .menuItem div {
    opacity: 1;
    margin: 0;
    width: auto;
  }
}

.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border-radius: 9px;
  padding: 16px;
  background-color: #fff;
  border: 1px solid #e5f0f9;
  width: 100%;
  position: relative;
}

.card h1 {
  color: #000;
  font-size: 17px;
  font-weight: 400;
  margin: 0;
  padding: 0;
  align-self: flex-start;
}

.card .card-button {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  height: 32px;
  border: none;
  border-radius: 4px;
  background-color: var(--color-blue-700);
  color: var(--color-white);
  cursor: pointer;
  padding: 10px 12px;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
}
@media only screen and (max-width: 600px) {
  .card {
    min-height: 266px;
  }
}

.doughnutChart {
  position: relative;
}

.doughnutChart .doughnutChart-container {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 200px;
}

.doughnutChart .dougnutChart-label {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  grid-gap: 2px;
  gap: 2px;
  position: absolute;
  bottom: 25%;
  width: 100%;
}

.dougnutChart-label #label-rating {
  color: var(--color-blue-900);
  font-size: 17px;
  font-family: 'Roboto', sans-serif;
  font-weight: 600;
}

.dougnutChart-label span {
  color: var(--color-blue-800);
  font-weight: 400;
  text-align: center;
  font-size: 15px;
}

.verticalbar {
  display: flex;
  padding: 20px;
  width: 100%;
}

.verticalbar-container {
  display: flex;
  grid-gap: 2px;
  gap: 2px;
  width: 100%;
  overflow: hidden;
}

.verticalbar-item {
  display: flex;
  flex-direction: column;
  text-align: center;
  transition: width 2s ease-in-out;
}

.verticalbar-meter {
  height: 24px;
  position: relative;
  background: #e5e5e5;
  overflow: hidden;
  margin-bottom: 7px;
}

.verticalbar-meter span {
  display: block;
  height: 100%;
}

.progress {
  -webkit-animation: progressBar 2s ease-in-out;
          animation: progressBar 2s ease-in-out;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
}

.progress.orange {
  background-color: #ff9500;
}

.progress.red {
  background-color: #ff3b30;
}

.progress.green {
  background-color: #34c759;
}

@-webkit-keyframes progressBar {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

@keyframes progressBar {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

.verticalbar-amountLabel {
  font-size: 17px;
  font-weight: 600;
  color: var(--color-blue-900);
}

.verticalbar-rangeLabel {
  font-size: 14px;
  font-weight: 300;
  color: var(--color-blue-700);
}

.chartStatistics .placeholderChart {
  width: 100%;
  background-color: var(--color-blue-200);
  height: 20px;
  margin-bottom: -50px;
}
.chartStatistics .placeholderText {
}

.dashboardPage {
  display: flex;
  width: 100%;
  flex: 6 1;
  font-family: "Roboto", sans-serif;
}

.dashboardPage-container {
  flex: 5 1;
  display: flex;
  flex-direction: column;
}

.dashboardPage-mainContent {
  display: flex;
  flex-direction: row;
  background-color: var(--color-blue-50);
  height: calc(100vh - var(--heightTopBar));
}

.dashboardPage .dashboardPage-trainerRole {
  width: 100%;
  text-align: center;
  margin-top: 50px;
}

.dashboardPage .dashboardPage-statisticsContainer {
  width: 100%;
  overflow-y: scroll;
  position: relative;
}

.dashboardPage .dashboardPage-statisticsContainer .lds {
  position: absolute;
  top: calc(50%);
  left: calc(50%);
}

.dashboardPage .dashboardStatistics {
  padding: 24px;
}

.dashboardStatistics .chartStatistics {
  display: flex;
  grid-gap: 24px;
  gap: 24px;
  margin-bottom: 24px;
}

.dashboardStatistics .chartStatistics .card {
  width: 100%;
}

.dashboardStatistics .dashboardStatistics-cards {
  display: flex;
  grid-gap: 24px;
  gap: 24px;
  justify-content: space-between;
}

.dashboardStatistics .dashboardStatistics-cards .card {
  align-items: flex-start;
  grid-gap: 24px;
  gap: 24px;
}

.dashboardStatistics .dashboardStatistics-cards .card span {
  color: var(--color-blue-800);
  font-size: 22px;
  font-weight: 400;
}

@media only screen and (max-width: 600px) {
  .dashboardPage-mainContent {
    flex-direction: column;
    height: auto;
  }
  .dashboardStatistics .chartStatistics {
    flex-direction: column;
  }
  .dashboardPage .dashboardPage-statisticsContainer {
    width: 100vw;
  }
  .dashboardStatistics .dashboardStatistics-cards {
    flex-direction: column;
  }
}

.addButton {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 42px;
  height: 32px;
  border: none;
  background-color: var(--color-blue-700);
  cursor: pointer;
  padding: 0;
  margin: 0;
  border-radius: 4px;
}

.addButton span {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 600px) {
  .addButton {
    margin-left: 15px;
  }
}

.agenda {
  width: calc(100% - var(--widthFilterBar));
  height: 100%;
  padding-left: 20px;
}

.agenda .rbc-day-slot:nth-child(2),
.agenda .rbc-time-header-content:nth-child(2) {
  --day-slot-color: #007aff;
}

.agenda .rbc-day-slot:nth-child(3),
.agenda .rbc-time-header-content:nth-child(3) {
  --day-slot-color: #ff9500;
}

.agenda .rbc-day-slot:nth-child(4),
.agenda .rbc-time-header-content:nth-child(4) {
  --day-slot-color: #34c759;
}

.agenda .rbc-day-slot:nth-child(5),
.agenda .rbc-time-header-content:nth-child(5) {
  --day-slot-color: #e60027;
}

.agenda .rbc-day-slot:nth-child(6),
.agenda .rbc-time-header-content:nth-child(6) {
  --day-slot-color: #c500e6;
}

.agenda .rbc-day-slot:nth-child(7),
.agenda .rbc-time-header-content:nth-child(7) {
  --day-slot-color: #005661;
}

.agenda .rbc-day-slot:nth-child(8),
.agenda .rbc-time-header-content:nth-child(8) {
  --day-slot-color: #ddda13;
}

.agenda .rbc-day-slot:nth-child(9),
.agenda .rbc-time-header-content:nth-child(9) {
  --day-slot-color: #f14e92;
}

.agenda .rbc-day-slot:nth-child(10),
.agenda .rbc-time-header-content:nth-child(10) {
  --day-slot-color: #01440f;
}

.agenda .rbc-day-slot:nth-child(11),
.agenda .rbc-time-header-content:nth-child(11) {
  --day-slot-color: #75aedb;
}

.agenda .rbc-day-slot:nth-child(12),
.agenda .rbc-time-header-content:nth-child(12) {
  --day-slot-color: #1000a0;
}

.agenda .rbc-day-slot:nth-child(13),
.agenda .rbc-time-header-content:nth-child(13) {
  --day-slot-color: #7e2e00;
}

.agenda .rbc-day-slot:nth-child(14),
.agenda .rbc-time-header-content:nth-child(14) {
  --day-slot-color: #777777;
}

.agenda .rbc-day-slot:nth-child(15),
.agenda .rbc-time-header-content:nth-child(15) {
  --day-slot-color: #b10000;
}

.agenda .rbc-day-slot:nth-child(16),
.agenda .rbc-time-header-content:nth-child(16) {
  --day-slot-color: #30d6ec;
}

.agenda .rbc-event {
  padding: 5px 0 5px 7px;
  opacity: 1;
  border-radius: 0;
  border: none;
  background-color: var(--day-slot-color);
  z-index: 1;
}

.agenda .rbc-events-container {
  margin-right: 0;
}

.agenda .rbc-event .event.normal {
  padding: 4px 0px 0px 4px;
  word-wrap: normal;
  position: relative;
}

.agenda .rbc-event .event.small {
  padding: 4px 0px 0px 4px;
  font-size: 14px;
  word-wrap: normal;
  position: relative;
}
.agenda .rbc-event .event.recurring {
  height: 100%;
}
.agenda .rbc-event .event.recurring svg {
  position: absolute;
  bottom: 4px;
  right: 6px;
  width: 15px;
}
.agenda .rbc-event .event.notRecurring svg {
  display: none;
}
.agenda .rbc-header {
  color: var(--day-slot-color);
}

.agenda .rbc-event::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 5px;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: -1;
}

.agenda .rbc-day-slot .rbc-event-content {
  color: #000;
}

.agenda .rbc-event-label {
  display: none;
}

.agenda .rbc-day-slot.rbc-time-column {
  background-color: #fff;
  margin-right: 20px;
  border-right: 1px solid #ddd;
}

.agenda .rbc-row.rbc-row-resource {
  border: none;
  height: 72px;
}

.agenda .rbc-toolbar {
  display: none;
}

.agenda .rbc-row-content {
  border: none;
  display: none;
}

.agenda .rbc-header {
  border: none;
  text-align: left;
  padding: 12px 8px;
  position: relative;
}

.agenda .rbc-header:after {
  content: var(--calendar-date);
  display: block;
  color: var(--color-black);
  font-weight: 500;
  font-size: 15px;
  font-family: "Roboto";
  position: absolute;
  top: 36px;
  left: 8px;
}

.agenda .rbc-label.rbc-time-header-gutter {
  display: none;
}

.agenda .rbc-time-view.rbc-time-view-resources {
  border: none;
}

.agenda .rbc-time-header-content {
  border-bottom: 3px solid #e5f0f9;
  background: #f7fafb;
  width: 100%;
  border-right: none;
  border-left: none;
  margin-right: 20px;
}

.agenda .rbc-time-content {
  border: none;
}

.agenda .rbc-time-header.rbc-overflowing,
.agenda .rbc-time-header {
  border: none;
  margin-left: 28px;
}

.agenda .rbc-timeslot-group {
  border: none;
}

.agenda .rbc-day-slot.rbc-time-column .rbc-timeslot-group,
.agenda .rbc-timeslot-group {
  min-height: 72px;
}

.agenda .rbc-time-header-content + .rbc-time-header-content {
  margin-left: 0;
}

@media only screen and (max-width: 600px) {
  .agenda {
    margin-left: 0;
  }
}

.outlineButton {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  border: 1px solid #2b6a93;
  padding: 6px 12px;
  cursor: pointer;
  border-radius: 4px;
  color: var(--color-blue-700);
  font-size: 14px;
  font-family: 'Roboto', sans-serif;
  height: 32px;
}

.calendarControls .calendarControls-container {
  display: flex;
  padding: 20px 24px;
}

.calendarControls .calendarControls-date .dateInput {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  border: 1px solid #2b6a93;
  padding: 6px 12px;
  cursor: pointer;
  border-radius: 4px;
  color: var(--color-blue-700);
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  height: 32px;
}

.calendarControls-container .calendarControls-slide {
  display: flex;
  margin: 0 16px;
  grid-gap: 6px;
  gap: 6px;
}

.calendarControls-container .calendarControls-views {
  display: flex;
  margin-left: 16px;
  grid-gap: 2px;
  gap: 2px;
}
.calendarControls-container .calendarControls-search {
  margin-left: 16px;
}

.calendarControls .outlineButton span {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 7px;
  gap: 7px;
}

.calendarControls .calendarControls-date svg,
.calendarControls .calendarControls-slide svg {
  width: 18px;
  height: 18px;
}

@media only screen and (max-width: 600px) {
  .calendarControls .calendarControls-container {
    flex-wrap: wrap;
    grid-gap: 6px;
    gap: 6px;
  }
  .calendarControls-container .calendarControls-slide {
    margin: 0;
  }
  .calendarControls-container .calendarControls-views {
    margin: 0;
    grid-gap: 6px;
    gap: 6px;
  }
  .calendarControls-container .calendarControls-search {
    margin-left: 0;
  }
}

.timeInput {
  background: #7db4e133;
  border: none;
  border-radius: 4px;
  padding: 10px 12px;
  color: var(--color-blue-900);
  font-family: 'Roboto', sans-serif;
}

.cancelAppointment-popup .cancelAppointment-popupFormContainer .cancelUpdateType p {
  font-size: 15px;
  margin: 0 0 20px 0;
}

.cancelAppointment-popup .popup .radioButtonContainer  {
  align-items: flex-start;
}

.cancelAppointment-popup .cancelStatus .radioButtonContainer .cancelAppointment-delete {
  display: flex;
  flex-direction: column;
}

.cancelAppointment-popup .cancelStatus .radioButtonContainer .cancelAppointment-delete span {
  font-size: 12px;
  font-style: italic;
  color: var(--color-dark-gray);
  margin-top: 4px;
}
.dayButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 45px;
  height: 45px;
  padding: 8px;
  border-radius: 50%;
  color: var(--color-blue-900);
  cursor: pointer;
}

.dayButtonNotActive {
  background-color: var(--color-blue-200);
}

.dayButtonActive {
  background-color: var(--color-blue-500);
}

.dayButton span {
  font-size: 15px;
  font-weight: 500;
}

.recurrence-popup .recurrence-days {
  width: 100%;
}

.recurrence-popup .recurrence-popupFormRow .recurrence-endRule {
  display: flex;
  flex-direction: column;
}

.recurrence-popup .recurrence-endRule label.inputLabel {
  margin-bottom: 14px;
}

.recurrence-popup .recurrence-days .daysContainer {
  display: flex;
  grid-gap: 18px;
  gap: 18px;
  justify-content: space-between;
  margin-top: 8px;
}

.recurrence-popup .popupOverlay .popupOverlay-mainContainer {
  padding: 20px 24px 0px 24px;
}

.recurrence-popup .recurrence-popupFormContainer .recurrence-popupFormRow {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
}

.recurrence-popup .recurrence-popupFormRow .recurrence-endRule .radioButtonContainer input.input {
  width: 75px;
  height: 38px;
  margin-left: 20px;
}

.recurrence-popup .recurrence-popupFormRow .recurrence-endRule .radioButtonContainer input.dateInput {
  width: 170px;
  height: 38px;
  margin-left: 20px;
}

.recurrence-popup .recurrence-popupFormRow .recurrence-days {
  width: 100%;
  margin-bottom: 10px;
}
.appointmentType-popup .popup {
  top: calc(50% - 150px);
}

.appointmentType-popup .popup-overlay .popup-body {
  border-radius: 10px 10px 0 0;
  margin: 0;
}

.appointmentType-popup .popup .popup-footer {
  padding: 0 16px 10px 16px;
  border: none
}

.appointmentType-popup .popup-overlay .popup-overlay-mainContainer {
  padding: 30px 24px 0 24px;
}

.appointmentType-popup .popup-overlay .popup {
  top: 25%;
  left: 50%;
}

.appointmentType-popup .popup p {
  font-size: 15px;
  margin: 0 0 20px 0;
}

.removeClients-popup .popup {
  top: calc(50% - 150px);
}

.removeClients-popup .removeClients-clientsList {
  display: grid;
  grid-template-columns: 60px 1fr;
  grid-row-gap: 15px;
  row-gap: 15px;
}

.removeClients-popup h2 {
  font-size: 15px;
  font-weight: normal;
  white-space: nowrap;
  margin: 0 0 20px 0;
  padding: 0;
}

.removeClients-popup .clientsList-name,
.removeClients-popup .checkbox-label {
  font-size: 14px;
}

.removeClients-popup .cancelStatus .cancelAppointment-delete {
  display: flex;
  flex-direction: column;
}

.removeClients-popup span {
  font-size: 12px;
  font-style: italic;
  color: var(--color-dark-gray);
  margin-top: 4px;
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 60px;
  height: 60px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 48px;
  height: 48px;
  margin: 6px;
  border: 6px solid var(--color-blue-400);
  border-radius: 50%;
  -webkit-animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
          animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: var(--color-blue-400) transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  -webkit-animation-delay: -0.45s;
          animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  -webkit-animation-delay: -0.3s;
          animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  -webkit-animation-delay: -0.15s;
          animation-delay: -0.15s;
}
@-webkit-keyframes lds-ring {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes lds-ring {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.agenda-popup .agenda-popupForm .agenda-popupFormContainer {
  display: flex;
  flex-direction: column;
}

.agenda-popup .agenda-popupFormContainer .agenda-popupFormRow {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.agenda-popup .agendaNote {
  width: 100%;
  height: 100%;
}

.agenda-popup .agendaNote label {
  color: var(--color-dark-gray);
  font-size: 14px;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
}

.agenda-popup .agendaNote textarea {
  padding: 10px;
  border-color: var(--color-blue-700);
  border-radius: 4px;
  color: var(--color-blue-900);
  width: 100%;
  height: 60px;
  resize: none;
  margin-top: 5px;
}

.agenda-popup .agenda-popupFormRow .agendaDateRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.agenda-popup .agendaDateRow .agendaDate-time {
  display: flex;
  grid-gap: 25px;
  gap: 25px;
  justify-content: space-between;
}

.agenda-popup .agendaDateRow .agendaDate-time .agendaDate-timeItem {
  display: flex;
  flex-direction: column;
}

.agenda-popup .agendaDateRow .agendaDate-time .agendaDate-timeItem .timeInput {
  width: 90px;
  height: 40px;
}

.agenda-popup .agendaDateRow .agendaDate-date input.dateInput {
  width: 200px;
}

.agenda-popup .agenda-popupFormRow .agenda-multiSelect,
.agenda-popup .agenda-popupFormRow .agenda-products,
.agenda-popup .agenda-popupFormRow .agenda-products select {
  width: 100%;
}

.agenda-popup .agenda-popupFormRow .editRecurrence-text {
  color: #98989d;
  font-style: italic;
  font-size: 15px;
  line-height: 1.4;
  margin: 0;
  letter-spacing: 0.3px;
}

.agenda-popup .agenda-popupFormRow .editRecurrence-edit {
  display: block;
  color: var(--color-blue-700);
  text-decoration: underline;
  font-weight: 500;
  cursor: pointer;
}

.agenda-popup .agenda-recurrence {
  margin-top: 10px;
  margin-bottom: 5px;
}

.agenda-popup .agenda-sendEmailConfirmation {
  margin-top: 10px;
}

.agenda-popup button:disabled {
  background-color: var(--color-blue-500);
  cursor: auto;
}

.agenda-popup .cancelAppointment-popup .popup .popup-footer .popup-buttonSection,
.agenda-popup .appointmentType-popup .popup .popup-footer .popup-buttonSection,
.agenda-popup .recurrence-popup .popup .popup-footer .popup-buttonSection {
  justify-content: flex-end;
}

@media only screen and (max-width: 600px) {
  .agenda-popupFormContainer .agenda-popupFormRow,
  .agenda-popupFormRow .agendaDateRow {
    flex-wrap: wrap;
  }
  .agendaDateRow .agendaDate-date {
    width: 100%;
  }
  .agenda-popupFormContainer .agenda-popupFormRow .inputContainer {
    width: 100%;
  }
  .agendaDateRow .agendaDate-time .agendaDate-timeItem {
    width: 45%;
  }
  .agendaDateRow .agendaDate-time .agendaDate-timeItem .timeInput {
    width: 100%;
  }
  .recurrence-days .daysContainer {
    grid-gap: 0;
    gap: 0;
  }
  .agendaPage .recurrence-popupFormContainer .recurrence-popupFormRow {
    flex-direction: column;
  }
}

.search-popup .appointments-container {
  overflow: scroll;
  max-height: calc(90vh - 160px);
  display: flex;
  margin-top: 20px;
  flex-direction: column;
}

.search-popup .loader-container {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.search-popup .loader-container .lds-ring {
  left: 10px;
}

.search-popup .noAppointments-container {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
}

.search-popup .search-input-container {
  display: flex;
  align-items: flex-end;
}

.search-popup .inputContainer {
  margin: 10px 10px 0 10px ;
}

.search-popup .outlineButton {
  height: 38px;
}

.search-popup input {
  width: 450px !important;
}

.search-popup .popup-overlay .popup-body {
  overflow-y: inherit;
}

.search-popup .popup-overlay .popup-container {
  height: 90vh;
}

.search-popup .popup-overlay .popup-footer {
  display: none;
}

.search-popup .appointments-container .appointment {
  color: inherit;
  border: none;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  text-align: left;
  margin: 0 0 20px 0;
  padding: 10px;
  border-left: 5px solid #007aff;
  background-color: var(--color-blue-400);
}

.search-popup .appointments-container .deleted-appointment {
  opacity: 0.5;
  background-color: #E85E51;
  border-left: 5px solid #ef301f;
  text-decoration: line-through;
}

.search-popup .appointments-container .cancelled-appointment {
  opacity: 0.5;
  text-decoration: line-through;
}

.search-popup .appointments-container .cancelledFree-appointment {
  opacity: 0.5;
  text-decoration: line-through;
  font-style: italic;
}

.search-popup .appointments-container .appointment h1 {
  margin: 0 0 10px 0;
  font-size: 24px;
}
.search-popup .appointments-container .appointment p {
  margin: 5px 0;
}

.search-popup .appointments-container .appointment .appointment-info-container {
  display: flex;
}

.search-popup .appointments-container .appointment .line-through {
  text-decoration: line-through;
}

.search-popup .appointments-container .appointment .italic {
  font-style: italic;
}

.search-popup .appointments-container .appointment .opacity {
  opacity: 0.5;
}

.search-popup
  .appointments-container
  .appointment
  .appointment-info-container
  div {
  flex: 1 1;
}

@media only screen and (max-width: 600px) {
  .search-popup input {
    width: 100% !important;
  }
}

.agendaPage {
  flex: 6 1;
  display: flex;
  width: 100%;
  font-family: "Roboto", sans-serif;
}

.agendaPage .agendaPage-main {
  flex: 5 1;
  display: flex;
  flex-direction: column;
}

.agendaPage .agendaPage-mainContent {
  height: calc(100vh - var(--heightTopBar));
  width: calc(100vw - var(--widthSideBarClosed));
  overflow-y: scroll;
  display: flex;
  flex-direction: row;
}

.agendaPage .popup select {
  width: 200px;
}

/* Agenda Popup */
.agendaPage .agenda-popup .popup .popup-body {
  width: 500px;
}

.agendaPage .popup .popup-footer .popup-buttonSection {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.agendaPage .removeClients-popup .popup .popup-footer .popup-buttonSection {
  justify-content: flex-end;
}

.agenda-popup .popup .popup-footer .popup-popupButton.button {
  background-color: transparent;
  color: red;
  font-size: 15px;
  font-weight: 400;
}

@media only screen and (max-width: 600px) {
  .agendaPage .agendaPage-mainContent {
    flex-direction: column;
    height: auto;
    width: 100vw;
  }
  .agendaPage .agenda-popup .popup .popup-body {
    width: 100%;
    height: auto;
  }
  .agendaPage .popup select,
  .agendaPage .popup input {
    width: 100%;
  }
}

.categoryBadge {
  display: flex;
  border-radius: 50px;
  grid-gap: 8px;
  gap: 8px;
  align-items: center;
  padding: 6px 15px;
  font-size: 14px;
  font-weight: 400;
  border: 1px solid var(--color-blue-500);
  color: var(--color-blue-700);
}

.categoryBadge svg {
  width: 18px;
  height: 18px;
  font-weight: 700;
}

.notificationItem {
  width: 100%;
  background-color: #fff;
  border-radius: 3px;
  border: 1px solid #e5f0f9;
  padding: 18px 16px;
  margin-bottom: 12px;
}

.notificationItem-headerContainer .notificationItem-header {
  display: flex;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
  border-bottom: 1px solid var(--color-blue-200);
  padding-bottom: 16px;
  font-size: 14px;
}

.notificationItem-header .notificationItem-headerIcon {
  display: flex;
  align-items: center;
  align-self: start;
}

.notificationItem-headerDate {
  font-size: 13px;
  font-weight: 400;
  color: #636366;
  margin-top: 5px;
}

.notificationItem .trainer,
.notificationItem .client {
  font-size: 14px;
  font-weight: 500;
  color: var(--color-blue-700);
}

.notificationItem .trainer {
  margin: 0 3px 0 0;
}

.notificationItem .client,
.notificationItem .goal {
  margin: 0 3px 0 3px;
}

.notificationItem .categories {
  display: flex;
  grid-gap: 15px;
  gap: 15px;
  padding: 16px 0;
  border-bottom: 1px solid var(--color-blue-200);
}

.notificationItem .notificationItem-buttonContainer {
  padding: 16px 0;
}

.notificationItem .notificationItem-button {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  height: 32px;
  border: none;
  border-radius: 4px;
  background-color: var(--color-blue-700);
  color: var(--color-white);
  cursor: pointer;
  padding: 12px 14px;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
}

.notificationItem .target span {
  margin: 0 3px 0 0;
}

@media only screen and (max-width: 600px) {
  .notificationItem .categories {
    flex-wrap: wrap;
  }
}

.notificationPage {
  display: flex;
  width: 100%;
  flex: 6 1;
  font-family: "Roboto", sans-serif;
}

.notificationPage-trainerRole {
  width: 100%;
  text-align: center;
  margin-top: 50px;
}

.notificationPage-mainContent {
  display: flex;
  flex-direction: row;
  background-color: var(--color-blue-50);
  height: calc(100vh - var(--heightTopBar));
  width: calc(100vw - var(--widthSideBarClosed));
}

.notificationPage-itemsContainer {
  width: 100%;
  overflow-y: scroll;
}

.notificationPage .notificationPage-notifications {
  margin: 10px 25px;
}

.notificationPage .notificationPage-loader {
  display: block;
  margin: 50px calc(50% - 50px);
}

@media only screen and (max-width: 600px) {
  .notification-mainContent {
    flex-direction: column;
    height: auto;
    width: 100vw;
  }
}

.editButton{
  border: none;
  background: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
}

.clientsPopup-formContainer {
  display: flex;
  flex-direction: column;
}

.clientsPopup-formContainer .clientsPopup-formRow {
  display: flex;
  justify-content: space-between;
  grid-gap: 15px;
  gap: 15px;
}

.clientsPopup-form .clientsPopup-section {
  display: flex;
  flex-direction: column;
  grid-gap: 15px;
  gap: 15px;
}

.clientsPopup-sectionHeaderContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 20px 0 10px 0;
}

.clientsPopup-sectionTitle {
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-weight: 500;
}

.clientsPopup-sectionHorizontalLine {
  flex: 1 1;
  height: 1px;
  margin-left: 24px;
  background-color: rgba(242, 242, 242, 1);
}

.clients-popup .popupOverlay .popup .popup-img img {
  max-width: 239px;
  max-height: 135px;
  border-radius: 4px;
}

.sliderPopup-cardContainer{
  padding: 16px;
  background-color: #fff;
  border: 1px solid rgba(229, 240, 249, 1);
  border-radius: 9px;
  margin-bottom: 24px;
}
.sliderPopup .sliderPopup-backgroundContainer {
  background-color: rgba(27, 27, 27, 0.4);
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  margin: 0;
  padding: 0;
  border: none;
  z-index: 4;
}

.sliderPopup .sliderPopup-container {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  z-index: 5;
}

.sliderPopup .sliderPopup-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: var(--heightTopBar);
  border-bottom: 1px solid var(--color-light-gray);
  padding: 12px 16px;
}

.sliderPopup .sliderPopup-headerLeft {
  font-size: 15px;
  font-weight: 600;
  color: var(--color-dark-gray);
}

.sliderPopup .sliderPopup-headerRight {
  display: flex;
  grid-gap: 18px;
  gap: 18px;
}

.sliderPopup .sliderPopup-mainContainer {
  display: flex;
  flex-direction: row;
  height: calc(100vh - var(--heightTopBar));
}

.sliderPopup .sliderPopup-sidebar {
  padding: 18px;
  height: 100%;
  background-color: rgba(253, 253, 253, 1);
  box-shadow: 1px 0px 0px 0px rgba(242, 242, 242, 1);
}

.sliderPopup .sliderPopup-sideBarLink {
  margin-bottom: 8px;
}

.sliderPopup .sliderPopup-main {
  background-color: rgba(247, 250, 251, 1);
  flex: 1 1;
  padding: 24px;
  overflow-y: scroll;
}

.sliderPopup .sliderPopup-cardHeaderContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 15px;
}

.sliderPopup .sliderPopup-cardTitle {
  margin: 0;
  padding: 0;
  font-size: 17px;
  font-weight: 400;
}

.sliderPopup .sliderPopup-cardHorizontalLine {
  flex: 1 1;
  height: 2px;
  margin-left: 24px;
  background-color: rgba(242, 242, 242, 1);
}

.sliderPopup .form-halfWidth {
  width: 49%;
}

.sliderPopup .form-quarterWidth {
  width: 23.5%;
}

.sliderPopup .form-rowOfTwo {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}

.sliderPopup .sliderPopup-button,
.sliderPopup .sliderPopup-buttonContainer #passwordRequest {
  display: flex;
  align-items: center;
  min-height: 40px;
  border: none;
  border-radius: 4px;
  background-color: var(--color-blue-700);
  color: var(--color-white);
  cursor: pointer;
  padding: 10px 12px;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
}

.sliderPopup .sliderPopup-buttonContainer a {
  display: flex;
  align-items: center;
  min-height: 40px;
  width: 250px;
  border: none;
  border-radius: 4px;
  background-color: var(--color-blue-700);
  color: var(--color-white);
  cursor: pointer;
  padding: 10px 12px;
  font-size: 14px;
}

.sliderPopup .button-outline {
  background-color: transparent;
  color: var(--color-blue-700);
  border: 1px solid var(--color-blue-700);
}

.sliderPopup .sliderPopup-buttonContainer {
  margin-top: 25px;
}
@media only screen and (max-width: 600px) {
  .sliderPopup {
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    -webkit-transform: translate(0);
            transform: translate(0);
    border-radius: 0;
    position: fixed;
  }
  .sliderPopup .sliderPopup-header {
    flex-wrap: wrap;
    height: auto;
    flex-direction: column-reverse;
  }
  .sliderPopup .sliderPopup-headerLeft {
    margin-top: 10px;
    width: 100%;
    text-align: center;
  }
  .sliderPopup .sliderPopup-sidebar {
    display: none;
  }
  .sliderPopup .sliderPopup-main {
    padding: 10px;
  }
  .sliderPopup .form-rowOfTwo {
    flex-direction: column;
  }
  .sliderPopup .form-halfWidth {
    width: 100%;
  }
  .sliderPopup .form-quarterWidth {
    width: 100%;
  }
  .sliderPopup .sliderPopup-mainContainer {
    height: auto;
    overflow: scroll;
  }
}

.editClientPopup .sm-select select,
.editClientPopup .sm-select input {
  width: 239px;
}

.editClientPopup .sliderPopup .sliderPopup-container {
  width: 1072px;
}

.editClientPopup .sliderPopup #MFImage {
  max-width: 239px;
  max-height: 135px;
  border-radius: 4px;
}

.editClientPopup .editClientPopup-image span {
  display: block;
  color: rgb(151 151 151);
  margin: 7px 0 15px 0;
  font-style: italic;
  font-size: 11px;
}

.editClientPopup .sliderPopup-buttonContainer {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  margin-top: 25px;
}

.editClientPopup .editClientPopup-products {
  display: grid;
  grid-template-columns: auto 90px;
  grid-column-gap: 10px;
  -webkit-column-gap: 10px;
          column-gap: 10px;
  margin-top: 10px;
}

.editClientPopup h2 {
  font-size: 14px;
  color: var(--color-dark-gray);
  margin: 0 0 5px 0;
  padding: 0;
  font-weight: 600;
}


@media only screen and (max-width: 600px) {
  .editClientPopup .sliderPopup .sliderPopup-container {
    width: 100%;
  }
  .editClientPopup .sm-select select,
  .editClientPopup .sm-select input {
    width: 100%;
  }
}

.client-weightlogs-popup .clientCaloriesAndClientGoalContainer {
  display: grid;
  grid-template-columns: 2fr 3fr;
  grid-gap: 20px;
}

.client-weightlogs-popup .clientgoalWrapper,
.client-weightlogs-popup .clientCaloriesWrapper {
  display: block;
}

.client-weightlogs-popup .clientgoalContainer,
.client-weightlogs-popup .clientCaloriesContainer,
.client-weightlogs-popup .clientgoalHeader,
.client-weightlogs-popup .clientCaloriesHeader {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin: 10px 0 20px 0;
}
.client-weightlogs-popup .clientgoalContainer .clientgoalItem,
.client-weightlogs-popup .clientCaloriesContainer .clientCaloriesItem {
  text-align: center;
}

.client-weightlogs-popup .clientgoalContainer .clientgoalItem p,
.client-weightlogs-popup .clientCaloriesContainer .clientCaloriesItem p {
  margin: 10px 0 0 0;
  font-size: 0.8rem;
}
.client-weightlogs-popup .addClientGoalButton {
  margin: 10px 0 0 0;
}

.client-weightlogs-popup .clientgoalContainer input,
.client-weightlogs-popup .clientgoalContainer .disabledInput,
.client-weightlogs-popup .clientCaloriesContainer input,
.client-weightlogs-popup .clientCaloriesContainer .disabledInput {
  width: 100% !important;
  border: 1px var(--color-blue-400) solid;
  margin: 5px 0;
  border-radius: 5px;
  padding: 6px 11px;
  transition: 200ms ease background-color;
  background: none;
  width: 100%;
  font-size: 1.2rem;
  color: black;
  text-align: center;
  font-family: "Roboto", sans-serif;
}
.client-weightlogs-popup .clientgoalContainer .buttonContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  margin: 0 !important;
}
.client-weightlogs-popup .weightlogsHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0 0 0;
  font-weight: 500;
}

.client-weightlogs-popup .addWeightLogButton {
  border: none;
  cursor: pointer;
  padding: 5px 20px;
  margin: 0;
  border-radius: 4px;
  background-color: var(--color-blue-700);
  color: white;
  border: 1px var(--color-blue-700) solid;
}

.client-weightlogs-popup .title {
  font-weight: 500;
  margin: 10px 0 0 0;
  display: inline-block;
}
.client-weightlogs-popup .clientgoalHeader .title,
.client-weightlogs-popup .clientCaloriesHeader .title {
  margin: 0 0 0 0;
}
.client-weightlogs-popup .weekContainer {
  margin: 10px 0 20px 0;
}

.client-weightlogs-popup .weekContainer .weekHeader {
  background-color: lightblue;
  display: flex;
  justify-content: space-between;
  padding: 5px 10px;
  border-radius: 5px;
  margin: 0 0 5px 0;
}
.client-weightlogs-popup .weekContainer .weekHeader p {
  margin: 0;
}
.client-weightlogs-popup .weekContainer .weekHeader .weekText {
  font-weight: 500;
}
.client-weightlogs-popup .dayContainer {
  border: 1px var(--color-blue-400) solid;
  margin: 5px 0;
  border-radius: 5px;
  padding: 6px 11px;
  transition: 200ms ease background-color;
  background: none;
  width: 100%;
  text-align: left;
  font-family: "Roboto", sans-serif;
  font-size: 1rem;
}
.client-weightlogs-popup .dayContainer:disabled {
  color: black;
}
.client-weightlogs-popup .dayContainer:hover {
  cursor: pointer;
  border: 2px var(--color-blue-400) solid;
  padding: 5px 10px;
}

.client-weightlogs-popup .dayHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.client-weightlogs-popup .dayHeader .weightInput {
  border: none;
  background: none;
  height: 40px;
  font-size: 1rem;
  margin: 0 10px 0 0;
  padding: 5px;
  color: black;
  border: 1px solid black;
  width: 75px;
}

.client-weightlogs-popup .noteContainer {
  margin: 20px 0 0 0;
}
.client-weightlogs-popup .noteInput {
  margin: 10px 0 10px 0;
  width: 100% !important;
  font-size: 1rem;
  height: 40px;
}

.client-weightlogs-popup .buttonContainer {
  display: flex;
  justify-content: center;
  margin: 0;
}
.client-weightlogs-popup .buttonContainer .saveButton,
.client-weightlogs-popup .buttonContainer .cancelButton {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  cursor: pointer;
  padding: 5px 10px;
  margin: 0;
  border-radius: 4px;
  background-color: white;
  margin: 0 5px;
  border: 1px var(--color-blue-700) solid;
}
.client-weightlogs-popup .clientgoalContainer .saveButton,
.client-weightlogs-popup .clientgoalContainer .cancelButton {
  margin: 5px 0 5px 0;
}
.client-weightlogs-popup .buttonContainer .saveButton,
.client-weightlogs-popup .clientgoalContainer .saveButton {
  color: white;
  background-color: var(--color-blue-700);
}


.filterButton {
  border: none;
  background: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 0;
  margin: 0;
}

.pagination {
  display: flex;
  justify-content: center;
}

.pageSizeContainer {
  text-align: center;
  margin-top: 5px;
}

.pageSizeContainer .pageSize {
  display: inline-block;
  padding: 10px;
  text-decoration: underline;
  cursor: pointer;
}

.paginationItem {
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  color: var(--color-blue-700);
  border: 2px solid var(--color-blue-700);
  padding: 10px 15px;
  border-radius: 5%;
  height: 35px;
  width: 35px;
  position: relative;
  margin: 0 5px;
  cursor: pointer;
}

.paginationItem-dots {
  display: flex;
  align-items: center;
  color: var(--color-blue-700);
}

.paginationItem.active {
  border: 2px solid var(--color-blue-700);
  background-color: var(--color-blue-700);
  color: white;
  border-radius: 5%;
}

.next,
.prev {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  cursor: pointer;
  background-color: transparent;
  color: var(--color-blue-700);
}

.prev.disabled,
.next.disabled {
  pointer-events: none;
  color: var(--color-blue-400);
  background-color: transparent;
}

.pagination-buttonContainer {
  display: flex;
  align-items: center;
}
.mfTableWrapper {
  width: 100%;
  height: 100%;
  overflow: auto;
}

.mfTableWrapper .lds-ring {
  display: block;
  margin: 50px auto;
}

.mfTable {
  display: grid;
}

.mfTable .tableRow {
  display: contents;
}

.mfTable tcxspan {
  text-decoration: none;
  pointer-events: none;
}

.mfTable .tableHeader .tableColumn,
.mfTable .tableFooter .tableColumn {
  display: flex;
  justify-content: space-between;
  align-items: center;

  font-weight: 600;
  padding: 0.875rem;
  text-align: left;
  border: 1px solid var(--color-blue-200);
  background-color: var(--color-blue-50);

  width: auto;
  white-space: nowrap;
}

@media only screen and (min-width: 601px) {
  .mfTable .tableHeader .tableColumn,
  .mfTable .tableFooter .tableColumn {
    position: -webkit-sticky;
    position: sticky;
  }
  .mfTable .tableHeader .tableColumn {
    top: -1px;
  }

  .mfTable .tableFooter .tableColumn {
    bottom: -1px;
  }
}

.mfTable .tableColumn {
  padding: 0.875rem;
  border: 0.5px solid var(--color-blue-200);

  width: auto;
  white-space: nowrap;
}

.mfTable .arrowDown {
  border: none;
  background: none;
  cursor: pointer;
  padding: 0;
  margin-left: auto;
  position: relative;
}

.mfTable .calculationSetter {
  position: absolute;
  display: flex;
  flex-direction: column;
  bottom: 20px;
  right: 0px;
  background-color: #fff;
  border-radius: 9px;
  border: 1px solid var(--color-blue-200);
  box-shadow: 0px 0px 15px 10px rgba(0, 0, 0, 0.15);
}

.mfTable .calculationSetter button {
  border: none;
  background: none;
  width: 100%;
  height: 20%;
  font-size: 14px;
  padding: 15px;
  text-align: left;
  cursor: pointer;
  font-family: "Roboto", sans-serif;
  color: var(--color-blue-700);
}

.mfTable .calculationSetter button:not(:last-child) {
  border-bottom: 1px solid var(--color-blue-200);
}

.mfTable .tableHeader .table-icons {
  display: flex;
  align-items: center;
  grid-gap: 5px;
  gap: 5px;
}

.mfTable .table-icons .table-iconClose {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.mfTable .tableRow:hover .tableColumn {
  background-color: var(--color-blue-50);
}

.mfTablePagination {
  margin: 70px 0;
}
.clientsPage {
  display: flex;
  width: 100%;
  flex: 6 1;
  font-family: "Roboto", sans-serif;
}

.clientsPage-container {
  flex: 5 1;
  display: flex;
  height: 100vh;
  flex-direction: column;
}

.clientsPage-mainContent {
  display: flex;
  height: calc(100vh - var(--heightTopBar));
  width: calc(100vw - var(--widthSideBarClosed));
  flex-direction: row;
}

.clientsPage .popup .popup-body {
  width: 550px;
}

.clientsPage .popup select,
.clientsPage .popup input {
  width: 239px;
}

.progression {
  display: inline-block;
  margin-right: 6px;
  border-radius: 50%;
  width: 12px;
  height: 12px;
}

.progression.large {
  width: 15px;
  height: 15px;
}

.tableColumn .green {
  background-color: #34c759;
}

.tableColumn .orange {
  background-color: #ff9500;
}

.tableColumn .red {
  background-color: #ff3b30;
}

.clientsPage .mfTable {
  grid-template-columns: auto 150px 120px repeat(7, auto) 150px 70px;
}

.clientsPage .stackContainer {
  display: flex;
  align-items: center;
}
.clientsPage .stackContainer .text {
  width: 50%;
  text-align: center;
}
.clientsPage .stackContainer .verticalLine {
  width: 1px;
  background-color: var(--color-blue-500);
  height: 16px;
  display: inline-block;
  margin: 0 10px;
}

@media only screen and (max-width: 600px) {
  .clientsPage-mainContent {
    flex-direction: column;
    height: auto;
    width: 100vw;
  }
  .clientsPage .popup .popup-body {
    width: 100%;
  }
  .clientsPage .popup .popup-img {
    justify-content: center;
    margin: 0;
  }
  .clientsPopup-formContainer .clientsPopup-formRow {
    flex-direction: column;
    grid-gap: 0;
    gap: 0;
  }
  .clientsPage .popup select,
  .clientsPage .popup input {
    width: 100%;
  }
}

.dotsButton {
  border: none;
  background: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
}

.editTrainerPopup .sliderPopup .sliderPopup-container {
  width: 1072px;
}

.editTrainerPopup #MFImage {
  max-width: 239px;
  max-height: 135px;
  border-radius: 4px;
}

.editTrainerPopup .editTrainerPopup-image span { 
  display: block;
  color: rgb(151 151 151);
  margin: 7px 0 10px 0;
  font-style: italic;
  font-size: 11px;
}

.editTrainerPopup .sliderPopup-cardContainer#clientSatisfaction .statistics {
  width: 100%;
}

.editTrainerPopup .sliderPopup-cardContainer .chartStatistics {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
}

.editTrainerPopup .sliderPopup-cardContainer#clientSatisfaction {
  background-color: transparent;
  border: none;
  padding: 0;
}

.editTrainerPopup
  .sliderPopup-cardContainer#clientSatisfaction
  .sliderPopup-cardHeaderContainer {
  display: none;
}

.editTrainerPopup .sliderPopup-cardContainer .statisticsCard h1::after {
  content: "";
  display: inline-block;
  top: 25px;
  width: 45%;
  right: 16px;
  height: 2px;
  position: absolute;
  background-color: rgba(242, 242, 242, 1);
}

.editTrainerPopup .sliderPopup-cardContainer#clientSatisfaction .verticalbar {
  margin-top: 35px;
}

@media only screen and (max-width: 600px) {
  .editTrainerPopup .sliderPopup .sliderPopup-container {
    width: 100%;
  }
  .editTrainerPopup .sliderPopup-cardContainer .chartStatistics {
    flex-direction: column;
  }
}

.trainersPage {
  display: flex;
  width: 100%;
  flex: 6 1;
  font-family: "Roboto", sans-serif;
}

.trainersPage-container {
  flex: 5 1;
  display: flex;
  flex-direction: column;
}

.trainersPage .trainersPage-trainerRole {
  width: 100%;
  text-align: center;
  margin-top: 50px;
}

.trainersPage-mainContent {
  height: calc(100vh - var(--heightTopBar));
  width: calc(100vw - var(--widthSideBarClosed));
  display: flex;
  flex-direction: row;
}
.trainersPage .mfTable {
  grid-template-columns: repeat(5, auto) 70px;
}

@media only screen and (max-width: 600px) {
  .trainersPage-mainContent {
    flex-direction: column;
    height: auto;
    width: 100vw;
  }
}

.downloadButton{
  border: none;
  background: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 0;
  margin: 0;
}

.invoicesPage {
  display: flex;
  width: 100%;
  flex: 6 1;
  font-family: "Roboto", sans-serif;
}

.invoicesPage-container {
  flex: 5 1;
  display: flex;
  flex-direction: column;
}

.invoicesPage-mainContent {
  height: calc(100vh - var(--heightTopBar));
  width: calc(100vw - var(--widthSideBarClosed));
  display: flex;
  flex-direction: row;
}

.invoicesPage .invoicesPage-trainerRole {
  width: 100%;
  text-align: center;
  margin-top: 50px;
}

.status {
  font-size: 14px;
  color: white;
  padding: 9px 15px;
  border-radius: 24px;
}

.statusOpen {
  background-color: #007aff;
}

.statusPending {
  background-color: #af52de;
}

.statusPaid {
  background-color: #34c759;
}

.statusLate {
  background-color: #ff9500;
}

.statusReminded {
  background-color: #c1c1c1;
}

.statusUncollectable {
  background-color: #f13f3f;
}

.invoicesPage .mfTable {
  grid-template-columns: repeat(7, auto);
}

@media only screen and (max-width: 600px) {
  .invoicesPage-mainContent {
    flex-direction: column;
    height: auto;
    width: 100vw;
  }
}

.clubsCard {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border-radius: 10px;
  border: 1px solid #e2e9f0;
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.03);
  width: 355px;
  font-family: "Roboto", sans-serif;
}

.clubsCard .clubsCard-body {
  padding: 16px 22px;
}

.clubsCard img {
  height: 228px;
  width: 100%;
  object-fit: cover;
  border-radius: 10px 10px 0 0;
}

.clubsCard .clubsCard-title {
  font-size: 17px;
  font-weight: 600;
  color: #000;
  margin: 0;
}

.clubsCard .clubsCard-body {
  display: flex;
  flex-direction: column;
  grid-gap: 15px;
  gap: 15px;
}

.clubsCard-body .clubsCard-text {
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
  gap: 10px;
}

.clubsCard-text .clubsCard-amount {
  display: flex;
  align-items: center;
}

.clubsCard-text .clubsCard-amount .amountIcon {
  margin-right: 12px;
}

.clubsCard-amount .amount {
  margin-right: 5px;
}

.clubsCard-textLocation {
  display: flex;
  grid-gap: 3px;
  gap: 3px;
  font-size: 14px;
}

.clubsCard-text .clubsCard-textIcons {
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
  gap: 10px;
}

.clubsCard-footer {
  padding: 10px 22px 12px 22px;
  display: flex;
}

.clubsCard-footer .clubsCard-button {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  height: 32px;
  border: none;
  border-radius: 4px;
  background-color: var(--color-blue-700);
  color: var(--color-white);
  cursor: pointer;
  padding: 12px 14px;
  margin: 0 2px;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
}

@media only screen and (max-width: 600px) {
  .clubsCard {
    width: 100%;
  }
}

.badge {
  background-color: var(--color-light-brown);
  font-size: 14px;
  padding: 10px 12px;
  margin-bottom: 12px;
  height: 40px;
  border-radius: 4px;
  border: transparent;
}

@media only screen and (max-width: 600px) {
  .badge {
    width: 100%;
  }
}

.clubsPage .popup-overlay .popup-body {
  min-width: 816px;
  min-height: 500px;
}

.clubsPage .clubs-popupFormContainer {
  display: flex;
  grid-gap: 30px;
  gap: 30px;
}

.clubs-popupFormContainer .clubs-popupColumn {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.clubs-popupFormContainer .clubs-popupColumn .clubs-popupFormRow {
  display: flex;
  grid-gap: 24px;
  gap: 24px;
}

.clubs-popupFormRow .inputContainer,
.clubs-popupFormRow .inputContainer input {
  width: 100%;
}

.clubsPage .clubs-popupFormContainer img {
  border-radius: 5px;
  margin: 10px 0;
}

.clubsPage .clubs-popupColumn .clubs-openingHours {
  display: grid;
  grid-template-columns: repeat(3, auto);
  grid-column-gap: 12px;
  -webkit-column-gap: 12px;
          column-gap: 12px;
}

.clubsPage .timeInput {
  width: 90px;
  margin-bottom: 12px;
}

.clubsPage .coloredInput {
  width: 100%;
}

.clubsPage .clubs-popupColumn h2 {
  margin: 0 0 4px 0;
  padding: 0;
  font-size: 14px;
  font-weight: 500;
}

@media only screen and (max-width: 600px) {
  .clubsPage .clubs-popupFormContainer {
    flex-direction: column;
  }
  .clubs-popupFormContainer .clubs-popupColumn .clubs-popupFormRow {
    flex-direction: column;
    grid-gap: 0;
    gap: 0;
  }
  .clubsPage .clubs-popupColumn .clubs-openingHours {
    grid-template-columns: 30% 30% 30%;
    grid-column-gap: 12px;
    -webkit-column-gap: 12px;
            column-gap: 12px;
  }
}

.moneybird-popup .popup .popup-buttonSection {
  display: flex;
}
.moneybird-popup select,
.moneybird-popup input {
  width: 350px;
}

.moneybird-popup select:disabled {
  opacity: 0.5;
}

.moneybird-popup .popup-overlay .popup-container {
  width: 410px;
}

.moneybird-popup .popup .popup-popupButton {
  margin: 0 2px;
}
.moneybird-popup .login-button {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  min-height: 40px;
  border: none;
  border-radius: 4px;
  background-color: var(--color-blue-700);
  color: var(--color-white);
  cursor: pointer;
  padding: 10px 12px;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
}

.moneybird-popup .save-button {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  border: 1px solid var(--color-blue-700);
  border-radius: 4px;
  background-color: var(--color-white);
  color: 1px solid var(--color-blue-700);
  cursor: pointer;
  padding: 5px 12px;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
}

.moneybird-popup .save-button:disabled {
  border: 1px solid grey;
  color: grey;
  cursor: default;
}
@media only screen and (max-width: 600px) {
  .moneybird-popup select,
  .moneybird-popup input {
    width: 100%;
  }
}

.clubsPage {
  display: flex;
  width: 100%;
  flex: 6 1;
}

.clubsPage .clubsPage-container {
  width: 100%;
}

.clubsPage .clubsPage-mainContent {
  height: calc(100vh - var(--heightTopBar));
  width: calc(100vw - var(--widthSideBarClosed));
  overflow-y: scroll;
}

.clubsPage .clubs-container {
  margin: 49px 48px;
}

.clubsPage .clubs-cards {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 20px 25px;
  gap: 20px 25px;
}

@media only screen and (max-width: 600px) {
  .clubsPage .clubsPage-mainContent {
    height: auto;
    width: 100vw;
  }
  .clubsPage .clubs-container {
    margin: 24px;
  }
}

.productsPage {
  display: flex;
  width: 100%;
  flex: 6 1;
  font-family: 'Roboto', sans-serif;
}

.productsPage-container {
  flex: 5 1;
  display: flex;
  flex-direction: column;
}

.productsPage-mainContent {
  height: calc(100vh - var(--heightTopBar));
  width: calc(100vw - var(--widthSideBarClosed));
  overflow-y: scroll;
}

.productsPage .productsPage-trainerRole {
  width: 100%;
  text-align: center;
  margin-top: 50px;
}

/* Product Popup */
.productsPage .popup-overlay .popup-body {
  overflow: hidden;
  margin-bottom: 10px;
}

.productsPage .products-popupForm .products-popupFormContainer {
  display: flex;
  flex-direction: column;
}

.productsPage .products-popupFormContainer .products-popupFormRow {
  display: flex;
  justify-content: space-between;
  grid-gap: 15px;
  gap: 15px;
}

.productsPage .popup .popup-buttonSection {
  display: flex;
  justify-content: space-between;

  /* TODO REMOVE WHEN IT IS POSSIBLE TO REMOVE A PRODUCT */
  justify-content: flex-end;
  width: 100%;
}

.products-popup .popup .popup-footer .popup-popupButton.button {
  background-color: transparent;
  color: red;
  font-size: 15px;
  font-weight: 400;
}

.productsPage .products-status .statusLabel {
  color: var(--color-dark-gray);
  font-size: 14px;
  font-weight: 500;
}

.productsPage .products-status .checkboxContainer,
.productsPage .products-status {
  margin-top: 10px;
}

.productsPage .popup-overlay .popup-container {
  width: 410px;
}

.productsPage .popup .products-popupFormContainer input {
  width: 100%;
}

.productsPage .popup select,
.productsPage .popup input {
  width: 170px;
  min-height: 40px;
}

.productsPage .mfTable {
  grid-template-columns: repeat(5, 1fr) 70px;
}

@media only screen and (max-width: 600px) {
  .productsPage-mainContent {
    height: auto;
    width: 100vw;
  }

  .productsPage .products-popupFormContainer .products-popupFormRow {
    flex-direction: column;
    grid-gap: 0;
    gap: 0;
  }
  .productsPage .popup select,
  .productsPage .popup input {
    width: 100%;
  }
}

.deleteButton {
  border: none;
  background: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 0;
  margin: 0;
}
.activitiesPage {
  display: flex;
  width: 100%;
  flex: 6 1;
  font-family: "Roboto", sans-serif;
}

.activitiesPage-container {
  flex: 5 1;
  display: flex;
  flex-direction: column;
}

.activitiesPage-mainContent {
  display: flex;
  height: calc(100vh - var(--heightTopBar));
  width: calc(100vw - var(--widthSideBarClosed));
  flex-direction: row;
}

.activitiesPage .activitiesPage-trainerRole {
  width: 100%;
  text-align: center;
  margin-top: 50px;
}

.activitiesPage-mainContent-tableWrapper {
  height: calc(100vh - var(--heightTopBar));
  width: calc(100vw - var(--widthSideBarClosed) - var(--widthFilterBar));
}

.activitiesPage .mfTable {
  grid-template-columns: repeat(7, auto) 70px;
}

.status {
  font-size: 14px;
  color: white;
  padding: 9px 15px;
  border-radius: 24px;
}

.statusScheduled {
  background-color: #007aff;
}

.statusCompleted {
  background-color: #af52de;
}

.statusInvoiced {
  background-color: #34c759;
}

.statusCancelled {
  background-color: #ff9500;
}

.statusCancelledFree {
  background-color: #c1c1c1;
}

.statusDeleted {
  background-color: #c73634;
}

.statusInvoicedCredit {
  background-color: #00918f;
}

.statusRescheduled {
  background-color: #718de9;
}

.statusRescheduledCancelled {
  background-color: #849b12;
}

.statusRescheduledCompleted {
  background-color: #df7cf8;
}

@media only screen and (max-width: 600px) {
  .activitiesPage-mainContent {
    flex-direction: column;
    height: auto;
    width: 100vw;
  }
}

.hoursPage {
  display: flex;
  width: 100%;
  flex: 6 1;
  font-family: "Roboto", sans-serif;
}

.hoursPage-container {
  flex: 5 1;
  display: flex;
  flex-direction: column;
}

.hoursPage-mainContent {
  display: flex;
  height: calc(100vh - var(--heightTopBar));
  width: calc(100vw - var(--widthSideBarClosed));
  flex-direction: row;
}

.hoursPage .hoursPage-trainerRole {
  width: 100%;
  text-align: center;
  margin-top: 50px;
}

.hoursPage .mfTable {
  grid-template-columns: repeat(4, auto);
}

@media only screen and (max-width: 600px) {
  .hoursPage-mainContent {
    flex-direction: column;
    height: auto;
    width: 100vw;
  }
}

.reviewsPage {
  display: flex;
  width: 100%;
  flex: 6 1;
  font-family: "Roboto", sans-serif;
}

.reviewsPage-container {
  flex: 5 1;
  display: flex;
  flex-direction: column;
}

.reviewsPage-mainContent {
  height: calc(100vh - var(--heightTopBar));
  width: calc(100vw - var(--widthSideBarClosed));
  display: flex;
  flex-direction: row;
}

.reviewsPage .reviewsPage-trainerRole {
  width: 100%;
  text-align: center;
  margin-top: 50px;
}

.reviewsPage .mfTable {
  grid-template-columns: repeat(3, auto) 1fr;
}

.reviewsPage-mainContent-tableWrapper {
  height: calc(100vh - var(--heightTopBar));
  width: calc(100vw - var(--widthSideBarClosed) - var(--widthFilterBar));
}

@media only screen and (max-width: 600px) {
  .reviewsPage-mainContent {
    flex-direction: column;
    height: auto;
    width: 100vw;
  }
}

.settingsPage {
  display: flex;
  width: 100%;
  flex: 6 1;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
}

.settingsPage .settingsPage-main {
  flex: 5 1;
  display: flex;
  flex-direction: column;
}

.settingsPage .settingsPage-mainContent {
  display: flex;
  flex-direction: column;
  height: calc(100vh - var(--heightTopBar));
  width: calc(100vw - var(--widthSideBarClosed));
  padding: 24px;
  overflow-y: scroll;
  grid-gap: 16px;
  gap: 16px;
}

.settingsPage-mainContent .settingsPage-row {
  width: 45%;
}

.settingsPage .settingsPage-item {
  margin-bottom: 20px;
}

.settingsPage .settingsPage-title {
  margin: 0;
  padding: 0;
  font-size: 17px;
  font-weight: 500;
  position: relative;
}

.settingsPage .settingsPage-subtitle {
  padding: 0;
  margin: 0 0 6px 0;
  font-size: 13px;
  font-weight: 500;
}

.settingsPage .settingsPage-heading {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
}

.settingsPage .title-horizontalLine {
  flex-grow: 1;
  height: 2px;
  margin-left: 12px;
  background-color: rgba(242, 242, 242, 1);
}

.settingsPage .inputContainer select {
  width: 360px;
}

.settingsPage .inputContainer label {
  font-size: 13px;
  font-weight: 500;
}

.settingsPage .settingsPage-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px 12px;
  cursor: pointer;
  border-radius: 4px;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  min-height: 32px;
  min-width: 80px;
}

.settingsPage .button-solid {
  background-color: var(--color-blue-700);
  color: var(--color-white);
  border: none;
}

.settingsPage .button-outline {
  background-color: #ffffff;
  color: var(--color-blue-700);
  border: 1px solid #2b6a93;
}

@media only screen and (max-width: 600px) {
  .settingsPage .settingsPage-mainContent {
    height: auto;
    width: 100vw;
  }
  .settingsPage .inputContainer select {
    width: 100%;
  }
  .settingsPage-mainContent .settingsPage-row {
    width: 100%;
  }
}

.checkImportedClientsPage {
  display: flex;
  width: 100%;
  flex: 6 1;
  font-family: 'Roboto', sans-serif;
}

.checkImportedClientsPage-container {
  flex: 5 1;
  display: flex;
  flex-direction: column;
}

.checkImportedClientsPage-mainContent {
  height: calc(100vh - var(--heightTopBar));
  width: calc(100vw - var(--widthSideBarClosed));
  overflow-y: scroll;
}

.checkImportedClientsPage .mfTable {
  grid-template-columns: repeat(9, auto);
}
.passwordRequestPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.passwordRequestPage .popup .popup-body {
  width: 320px;
}

.passwordRequestPage .popup-inputContainer {
  margin: 24px 0 25px 0;
}

.passwordRequestPage span {
  font-size: 14px;
}

.passwordRequestPage .popup-titleContainer .popup-title {
  margin-bottom: 25px;
}
.passwordResetPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.passwordResetPage .popup .popup-body {
  width: 320px;
}

.passwordResetPage .popup-titleContainer .popup-title {
  margin-bottom: 5px;
}

.passwordResetPage .popup-inputContainer {
  margin: 24px 0 25px 0;
}
.notification {
  max-width: 600px;
  width: 100%;
  padding: 20px;
  position: relative;
  font-family: 'Karla', sans-serif;
  font-size: 1rem;
  border-radius: 10px;
  overflow: hidden;
  margin: 0 0 10px 0;
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.03);
  cursor: pointer;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.notification.error {
  background-color: #e12626;
  color: #fdfdfd;
}

.notification.success {
  background-color: #0caa1b;
  color: #fdfdfd;
}

.notification.warning {
  background-color: #e1aa1b;
  color: #fdfdfd;
}

.notification.info {
  background-color: #23aac5;
  color: #fdfdfd;
}

.notification .progress {
  position: absolute;
  bottom: 0;
  height: 5px;
  background-color: rgba(255, 255, 255, 0.6);
  transition: right 1s linear;
}

.notificationContainer {
  position: fixed;
  left: 10px;
  right: 10px;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 40;
}

.passwordFirstChangePage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.passwordFirstChangePage .popup .popup-body {
  width: 320px;
}

.passwordFirstChangePage .popup-titleContainer .popup-title {
  margin-bottom: 5px;
}

.passwordFirstChangePage .popup-inputContainer {
  margin: 24px 0 25px 0;
}

/**
 * We know this stylesheet will always be included.
 * And since we want to avoid inline styles to prevent injection,
 * we'll just put the global styles here.
 **/

:root {
  --color-red-500: #e27e7e;
  --color-green-500: #b0e27e;
  --color-gray-500: #E6E6E6;
  --color-blue-500: #7eb5e2;
  --color-blue-700: #2b6a93;
  --color-blue-800: #00486e;
  --color-blue-900: #00294c;
  --color-blue-400: #bedaf0;
  --color-blue-200: #e5f0f9;
  --color-blue-50: #f7fafb;
  --color-dark-gray: #1c1c1e;
  --color-light-gray: #e0e0e0;
  --color-black: #111111;
  --color-white: #fff;
  --color-light-brown: #f2f2f2;
  --color-good: #34c759;
  --color-mediocre: #ff9500;
  --color-bad: #ff3b30;
  --heightTopBar: 72px;
  --widthSideBarOpen: 220px;
  --widthSideBarClosed: 100px;
  --widthFilterBar: 240px;
  --border-light-gray: 1px solid #e0e0e0;
}

html,
body {
  margin: 0;
  border: 0;
  padding: 0;
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
}

* {
  box-sizing: border-box;
}

*::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: none;
}

*::-webkit-scrollbar-thumb {
  background-color: #bedaf0;
  background-color: var(--color-blue-400);
  border-radius: 4px;
}

*::-webkit-scrollbar-button {
  display: none;
}

.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.app-pageContainer {
  flex-grow: 1;
  display: flex;
  position: relative;
}

.active {
  background-color: #e5f0f9;
  background-color: var(--color-blue-200);
  border-radius: 40px;
}

a {
  font-family: "Roboto", sans-serif;
  color: #2b6a93;
  color: var(--color-blue-700);
  font-size: 13px;
  text-decoration: none;
}

@media only screen and (max-width: 600px) {
  .app {
    min-height: -webkit-fill-available;
  }

  html,
  body {
    height: -webkit-fill-available;
  }
}

