.agenda {
  width: calc(100% - var(--widthFilterBar));
  height: 100%;
  padding-left: 20px;
}

.agenda .rbc-day-slot:nth-child(2),
.agenda .rbc-time-header-content:nth-child(2) {
  --day-slot-color: #007aff;
}

.agenda .rbc-day-slot:nth-child(3),
.agenda .rbc-time-header-content:nth-child(3) {
  --day-slot-color: #ff9500;
}

.agenda .rbc-day-slot:nth-child(4),
.agenda .rbc-time-header-content:nth-child(4) {
  --day-slot-color: #34c759;
}

.agenda .rbc-day-slot:nth-child(5),
.agenda .rbc-time-header-content:nth-child(5) {
  --day-slot-color: #e60027;
}

.agenda .rbc-day-slot:nth-child(6),
.agenda .rbc-time-header-content:nth-child(6) {
  --day-slot-color: #c500e6;
}

.agenda .rbc-day-slot:nth-child(7),
.agenda .rbc-time-header-content:nth-child(7) {
  --day-slot-color: #005661;
}

.agenda .rbc-day-slot:nth-child(8),
.agenda .rbc-time-header-content:nth-child(8) {
  --day-slot-color: #ddda13;
}

.agenda .rbc-day-slot:nth-child(9),
.agenda .rbc-time-header-content:nth-child(9) {
  --day-slot-color: #f14e92;
}

.agenda .rbc-day-slot:nth-child(10),
.agenda .rbc-time-header-content:nth-child(10) {
  --day-slot-color: #01440f;
}

.agenda .rbc-day-slot:nth-child(11),
.agenda .rbc-time-header-content:nth-child(11) {
  --day-slot-color: #75aedb;
}

.agenda .rbc-day-slot:nth-child(12),
.agenda .rbc-time-header-content:nth-child(12) {
  --day-slot-color: #1000a0;
}

.agenda .rbc-day-slot:nth-child(13),
.agenda .rbc-time-header-content:nth-child(13) {
  --day-slot-color: #7e2e00;
}

.agenda .rbc-day-slot:nth-child(14),
.agenda .rbc-time-header-content:nth-child(14) {
  --day-slot-color: #777777;
}

.agenda .rbc-day-slot:nth-child(15),
.agenda .rbc-time-header-content:nth-child(15) {
  --day-slot-color: #b10000;
}

.agenda .rbc-day-slot:nth-child(16),
.agenda .rbc-time-header-content:nth-child(16) {
  --day-slot-color: #30d6ec;
}

.agenda .rbc-event {
  padding: 5px 0 5px 7px;
  opacity: 1;
  border-radius: 0;
  border: none;
  background-color: var(--day-slot-color);
  z-index: 1;
}

.agenda .rbc-events-container {
  margin-right: 0;
}

.agenda .rbc-event .event.normal {
  padding: 4px 0px 0px 4px;
  word-wrap: normal;
  position: relative;
}

.agenda .rbc-event .event.small {
  padding: 4px 0px 0px 4px;
  font-size: 14px;
  word-wrap: normal;
  position: relative;
}
.agenda .rbc-event .event.recurring {
  height: 100%;
}
.agenda .rbc-event .event.recurring svg {
  position: absolute;
  bottom: 4px;
  right: 6px;
  width: 15px;
}
.agenda .rbc-event .event.notRecurring svg {
  display: none;
}
.agenda .rbc-header {
  color: var(--day-slot-color);
}

.agenda .rbc-event::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 5px;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: -1;
}

.agenda .rbc-day-slot .rbc-event-content {
  color: #000;
}

.agenda .rbc-event-label {
  display: none;
}

.agenda .rbc-day-slot.rbc-time-column {
  background-color: #fff;
  margin-right: 20px;
  border-right: 1px solid #ddd;
}

.agenda .rbc-row.rbc-row-resource {
  border: none;
  height: 72px;
}

.agenda .rbc-toolbar {
  display: none;
}

.agenda .rbc-row-content {
  border: none;
  display: none;
}

.agenda .rbc-header {
  border: none;
  text-align: left;
  padding: 12px 8px;
  position: relative;
}

.agenda .rbc-header:after {
  content: var(--calendar-date);
  display: block;
  color: var(--color-black);
  font-weight: 500;
  font-size: 15px;
  font-family: "Roboto";
  position: absolute;
  top: 36px;
  left: 8px;
}

.agenda .rbc-label.rbc-time-header-gutter {
  display: none;
}

.agenda .rbc-time-view.rbc-time-view-resources {
  border: none;
}

.agenda .rbc-time-header-content {
  border-bottom: 3px solid #e5f0f9;
  background: #f7fafb;
  width: 100%;
  border-right: none;
  border-left: none;
  margin-right: 20px;
}

.agenda .rbc-time-content {
  border: none;
}

.agenda .rbc-time-header.rbc-overflowing,
.agenda .rbc-time-header {
  border: none;
  margin-left: 28px;
}

.agenda .rbc-timeslot-group {
  border: none;
}

.agenda .rbc-day-slot.rbc-time-column .rbc-timeslot-group,
.agenda .rbc-timeslot-group {
  min-height: 72px;
}

.agenda .rbc-time-header-content + .rbc-time-header-content {
  margin-left: 0;
}

@media only screen and (max-width: 600px) {
  .agenda {
    margin-left: 0;
  }
}
