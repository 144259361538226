.clientsPage {
  display: flex;
  width: 100%;
  flex: 6;
  font-family: "Roboto", sans-serif;
}

.clientsPage-container {
  flex: 5;
  display: flex;
  height: 100vh;
  flex-direction: column;
}

.clientsPage-mainContent {
  display: flex;
  height: calc(100vh - var(--heightTopBar));
  width: calc(100vw - var(--widthSideBarClosed));
  flex-direction: row;
}

.clientsPage .popup .popup-body {
  width: 550px;
}

.clientsPage .popup select,
.clientsPage .popup input {
  width: 239px;
}

.progression {
  display: inline-block;
  margin-right: 6px;
  border-radius: 50%;
  width: 12px;
  height: 12px;
}

.progression.large {
  width: 15px;
  height: 15px;
}

.tableColumn .green {
  background-color: #34c759;
}

.tableColumn .orange {
  background-color: #ff9500;
}

.tableColumn .red {
  background-color: #ff3b30;
}

.clientsPage .mfTable {
  grid-template-columns: auto 150px 120px repeat(7, auto) 150px 70px;
}

.clientsPage .stackContainer {
  display: flex;
  align-items: center;
}
.clientsPage .stackContainer .text {
  width: 50%;
  text-align: center;
}
.clientsPage .stackContainer .verticalLine {
  width: 1px;
  background-color: var(--color-blue-500);
  height: 16px;
  display: inline-block;
  margin: 0 10px;
}

@media only screen and (max-width: 600px) {
  .clientsPage-mainContent {
    flex-direction: column;
    height: auto;
    width: 100vw;
  }
  .clientsPage .popup .popup-body {
    width: 100%;
  }
  .clientsPage .popup .popup-img {
    justify-content: center;
    margin: 0;
  }
  .clientsPopup-formContainer .clientsPopup-formRow {
    flex-direction: column;
    gap: 0;
  }
  .clientsPage .popup select,
  .clientsPage .popup input {
    width: 100%;
  }
}
