.agendaPage .showProfile .popup .popup-footer .popup-buttonSection {
  justify-content: flex-end;
}

.topbarContainerSpaceBetween {
  display: flex;
  height: var(--heightTopBar);
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: var(--color-white);
  border-bottom: 1px solid var(--color-light-gray);
  font-family: "Roboto", sans-serif;
  font-size: 1rem;
}

.topbarContainerFlexEnd {
  display: flex;
  height: var(--heightTopBar);
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  background-color: var(--color-white);
  border-bottom: 1px solid var(--color-light-gray);
  padding: 0 24px;
  font-family: "Roboto", sans-serif;
  font-size: 1rem;
}

.topbar-right {
  flex: 1;
  margin: 0 !important;
  height: 100%;
  max-width: var(--widthFilterBar);
  min-width: var(--widthFilterBar);
  max-width: var(--widthFilterBar);
}

.topbar-right .topbar-rightContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  height: 100%;
  width: 100%;
}

.topbar-right h2 {
  padding: 0;
  margin: 0;
  font-size: 22px;
  font-weight: 500;
  color: var(--color-blue-800);
}

.childrenContainer {
  flex: 5;
}

.topbar-user {
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
  color: var(--color-blue-700);
  padding-right: 40px;
  font-weight: 500;
}

.topbar-user img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  object-fit: cover;
}

.topbar-user .dropdown-container .dropdown {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.dropdown-container {
  position: relative;
}

.dropdown-content {
  position: absolute;
  top: 37px;
  right: 6px;
  width: 180px;

  background-color: var(--color-white);
  z-index: 4;
  border-radius: 10px;
  color: var(--color-blue-700);
  font-size: 14px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);

  display: flex;
  flex-direction: column;
}

.dropdown-contentItem {
  height: 100%;
}

.dropdown-contentItem a {
  display: flex;
  align-items: center;
  padding: 12px 0px;
  margin: 0 16px;
  gap: 25px;
  cursor: pointer;
  font-weight: 400;
}

.dropdown-content .dropdown-contentItem:not(:first-child) {
  border-top: 1px solid #e5e5ea;
}
.hamburgerButton {
  border: none;
  margin: 0;
  padding: 0;
  background-color: transparent;
  cursor: pointer;
}
.hamburgerButtonSVG {
  width: 20px;
}

.hamburgerButtonSVG .topLine {
  transform-origin: top right;
}
.hamburgerButtonSVG .bottomLine {
  transform-origin: bottom right;
}

@media only screen and (max-width: 600px) {
  .topbarContainerSpaceBetween {
    flex-wrap: wrap;
    min-height: var(--heightTopBar);
    height: auto;
    align-items: flex-start;
  }
  .topbarContainerSpaceBetween .topbar-right {
    order: 1;
    flex: 0;
  }
  .topbarContainerSpaceBetween .childrenContainer {
    order: 3;
    flex: 0;
  }
  .topbarContainerSpaceBetween .topbar-user {
    order: 2;
    padding-right: 15px;
  }
  .topbar-right,
  .topbar-user {
    min-width: auto;
    max-width: auto;
    min-height: var(--heightTopBar);
    align-items: center;
    display: flex;
  }
  .topbar-user {
    padding-right: 0;
  }
}
