select {
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  color: var(--color-blue-900);
  background-color: rgba(125, 180, 225, 0.2);
  border: 1px solid #e2e9f0;
  border-radius: 4px;
  padding: 0 10px 0 10px;
  width: 100%;
  min-height: 40px;
}
