.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border-radius: 9px;
  padding: 16px;
  background-color: #fff;
  border: 1px solid #e5f0f9;
  width: 100%;
  position: relative;
}

.card h1 {
  color: #000;
  font-size: 17px;
  font-weight: 400;
  margin: 0;
  padding: 0;
  align-self: flex-start;
}

.card .card-button {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  height: 32px;
  border: none;
  border-radius: 4px;
  background-color: var(--color-blue-700);
  color: var(--color-white);
  cursor: pointer;
  padding: 10px 12px;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
}
@media only screen and (max-width: 600px) {
  .card {
    min-height: 266px;
  }
}
