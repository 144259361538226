.filterbar {
  width: var(--widthFilterBar);
  height: 100%;
  background-color: #fdfdfd;
  border-right: 1px solid #f2f2f2;
  min-width: var(--widthFilterBar);
  overflow-y: scroll;
  overflow-x: hidden;
}

.filterbar .filterbar-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 20px 24px;
}

.filterbar-container .filter-section {
  border-bottom: 1px solid var(--color-light-gray);
  margin-bottom: 22px;
}

.filter-section .filter-sectionContainer {
  margin-bottom: 20px;
}
.filter-section .filter-titleContainer {
  display: flex;
  justify-content: space-between;
}
.filter-filterOpenButton {
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 10px;
  border: none;
  background-color: var(--color-blue-700);
  cursor: pointer;
  border-radius: 4px;
  color: white;
}
.filterbar h1 {
  margin-bottom: 10px;
  padding: 0;
  font-size: 15px;
  font-weight: 500;
}

.filterbar .priceFilter-header,
.filterbar .dateFilter-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.filterbar .resetFilter {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.filterbar .priceFilter .priceFilter-main {
  display: flex;
  justify-content: space-between;
  gap: 15px;
}

.filterbar .dateFilter .dateFilter-main {
  display: flex;
  flex-direction: column;
}

.filterbar .priceFilter .inputContainer {
  width: 100%;
}

.filterbar .priceFilter input {
  width: 100%;
}

@media only screen and (max-width: 600px) {
  .filterbar {
    width: 100vw;
  }
  .filter-section .filter-sectionContainer {
    margin-bottom: 5px;
  }
  .filterbar-container .filter-section {
    margin-bottom: 10px;
  }
  .filterbar .filterbar-container {
    padding: 10px 15px;
  }
}
