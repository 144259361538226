.search-popup .appointments-container {
  overflow: scroll;
  max-height: calc(90vh - 160px);
  display: flex;
  margin-top: 20px;
  flex-direction: column;
}

.search-popup .loader-container {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.search-popup .loader-container .lds-ring {
  left: 10px;
}

.search-popup .noAppointments-container {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
}

.search-popup .search-input-container {
  display: flex;
  align-items: flex-end;
}

.search-popup .inputContainer {
  margin: 10px 10px 0 10px ;
}

.search-popup .outlineButton {
  height: 38px;
}

.search-popup input {
  width: 450px !important;
}

.search-popup .popup-overlay .popup-body {
  overflow-y: inherit;
}

.search-popup .popup-overlay .popup-container {
  height: 90vh;
}

.search-popup .popup-overlay .popup-footer {
  display: none;
}

.search-popup .appointments-container .appointment {
  color: inherit;
  border: none;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  text-align: left;
  margin: 0 0 20px 0;
  padding: 10px;
  border-left: 5px solid #007aff;
  background-color: var(--color-blue-400);
}

.search-popup .appointments-container .deleted-appointment {
  opacity: 0.5;
  background-color: #E85E51;
  border-left: 5px solid #ef301f;
  text-decoration: line-through;
}

.search-popup .appointments-container .cancelled-appointment {
  opacity: 0.5;
  text-decoration: line-through;
}

.search-popup .appointments-container .cancelledFree-appointment {
  opacity: 0.5;
  text-decoration: line-through;
  font-style: italic;
}

.search-popup .appointments-container .appointment h1 {
  margin: 0 0 10px 0;
  font-size: 24px;
}
.search-popup .appointments-container .appointment p {
  margin: 5px 0;
}

.search-popup .appointments-container .appointment .appointment-info-container {
  display: flex;
}

.search-popup .appointments-container .appointment .line-through {
  text-decoration: line-through;
}

.search-popup .appointments-container .appointment .italic {
  font-style: italic;
}

.search-popup .appointments-container .appointment .opacity {
  opacity: 0.5;
}

.search-popup
  .appointments-container
  .appointment
  .appointment-info-container
  div {
  flex: 1;
}

@media only screen and (max-width: 600px) {
  .search-popup input {
    width: 100% !important;
  }
}
