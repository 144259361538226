.clubsPage {
  display: flex;
  width: 100%;
  flex: 6;
}

.clubsPage .clubsPage-container {
  width: 100%;
}

.clubsPage .clubsPage-mainContent {
  height: calc(100vh - var(--heightTopBar));
  width: calc(100vw - var(--widthSideBarClosed));
  overflow-y: scroll;
}

.clubsPage .clubs-container {
  margin: 49px 48px;
}

.clubsPage .clubs-cards {
  display: flex;
  flex-wrap: wrap;
  gap: 20px 25px;
}

@media only screen and (max-width: 600px) {
  .clubsPage .clubsPage-mainContent {
    height: auto;
    width: 100vw;
  }
  .clubsPage .clubs-container {
    margin: 24px;
  }
}
