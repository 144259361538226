.settingsPage {
  display: flex;
  width: 100%;
  flex: 6 1;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
}

.settingsPage .settingsPage-main {
  flex: 5;
  display: flex;
  flex-direction: column;
}

.settingsPage .settingsPage-mainContent {
  display: flex;
  flex-direction: column;
  height: calc(100vh - var(--heightTopBar));
  width: calc(100vw - var(--widthSideBarClosed));
  padding: 24px;
  overflow-y: scroll;
  gap: 16px;
}

.settingsPage-mainContent .settingsPage-row {
  width: 45%;
}

.settingsPage .settingsPage-item {
  margin-bottom: 20px;
}

.settingsPage .settingsPage-title {
  margin: 0;
  padding: 0;
  font-size: 17px;
  font-weight: 500;
  position: relative;
}

.settingsPage .settingsPage-subtitle {
  padding: 0;
  margin: 0 0 6px 0;
  font-size: 13px;
  font-weight: 500;
}

.settingsPage .settingsPage-heading {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
}

.settingsPage .title-horizontalLine {
  flex-grow: 1;
  height: 2px;
  margin-left: 12px;
  background-color: rgba(242, 242, 242, 1);
}

.settingsPage .inputContainer select {
  width: 360px;
}

.settingsPage .inputContainer label {
  font-size: 13px;
  font-weight: 500;
}

.settingsPage .settingsPage-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px 12px;
  cursor: pointer;
  border-radius: 4px;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  min-height: 32px;
  min-width: 80px;
}

.settingsPage .button-solid {
  background-color: var(--color-blue-700);
  color: var(--color-white);
  border: none;
}

.settingsPage .button-outline {
  background-color: #ffffff;
  color: var(--color-blue-700);
  border: 1px solid #2b6a93;
}

@media only screen and (max-width: 600px) {
  .settingsPage .settingsPage-mainContent {
    height: auto;
    width: 100vw;
  }
  .settingsPage .inputContainer select {
    width: 100%;
  }
  .settingsPage-mainContent .settingsPage-row {
    width: 100%;
  }
}
