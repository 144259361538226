.reviewsPage {
  display: flex;
  width: 100%;
  flex: 6;
  font-family: "Roboto", sans-serif;
}

.reviewsPage-container {
  flex: 5;
  display: flex;
  flex-direction: column;
}

.reviewsPage-mainContent {
  height: calc(100vh - var(--heightTopBar));
  width: calc(100vw - var(--widthSideBarClosed));
  display: flex;
  flex-direction: row;
}

.reviewsPage .reviewsPage-trainerRole {
  width: 100%;
  text-align: center;
  margin-top: 50px;
}

.reviewsPage .mfTable {
  grid-template-columns: repeat(3, auto) 1fr;
}

.reviewsPage-mainContent-tableWrapper {
  height: calc(100vh - var(--heightTopBar));
  width: calc(100vw - var(--widthSideBarClosed) - var(--widthFilterBar));
}

@media only screen and (max-width: 600px) {
  .reviewsPage-mainContent {
    flex-direction: column;
    height: auto;
    width: 100vw;
  }
}
