.clubsPage .popup-overlay .popup-body {
  min-width: 816px;
  min-height: 500px;
}

.clubsPage .clubs-popupFormContainer {
  display: flex;
  gap: 30px;
}

.clubs-popupFormContainer .clubs-popupColumn {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.clubs-popupFormContainer .clubs-popupColumn .clubs-popupFormRow {
  display: flex;
  gap: 24px;
}

.clubs-popupFormRow .inputContainer,
.clubs-popupFormRow .inputContainer input {
  width: 100%;
}

.clubsPage .clubs-popupFormContainer img {
  border-radius: 5px;
  margin: 10px 0;
}

.clubsPage .clubs-popupColumn .clubs-openingHours {
  display: grid;
  grid-template-columns: repeat(3, auto);
  column-gap: 12px;
}

.clubsPage .timeInput {
  width: 90px;
  margin-bottom: 12px;
}

.clubsPage .coloredInput {
  width: 100%;
}

.clubsPage .clubs-popupColumn h2 {
  margin: 0 0 4px 0;
  padding: 0;
  font-size: 14px;
  font-weight: 500;
}

@media only screen and (max-width: 600px) {
  .clubsPage .clubs-popupFormContainer {
    flex-direction: column;
  }
  .clubs-popupFormContainer .clubs-popupColumn .clubs-popupFormRow {
    flex-direction: column;
    gap: 0;
  }
  .clubsPage .clubs-popupColumn .clubs-openingHours {
    grid-template-columns: 30% 30% 30%;
    column-gap: 12px;
  }
}
